export function generateDarkColorFromId(id) {
    // Enkel hashfunktion
    const hash = (id * 2654435761) % Math.pow(2, 32)

    // Extrahera RGB-komponenter
    let r = (hash & 0xff0000) >> 16
    let g = (hash & 0x00ff00) >> 8
    let b = hash & 0x0000ff

    // Begränsa varje komponent till intervallet 64-192
    // Detta ger ljusare färger men fortfarande tillräckligt mörka för vit text
    r = Math.floor(48 + (r * 128) / 255)
    g = Math.floor(48 + (g * 128) / 255)
    b = Math.floor(48 + (b * 128) / 255)

    // Konvertera till HEX och returnera
    return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`
}