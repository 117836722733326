import React, {useContext, useEffect, useState} from 'react';
import {useForm, useWatch} from "react-hook-form";
import AppContext from "../../../AppContext";
import {CancelButton, RemoveButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemDate from "../../../components/EditForm/FormItemDate";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemEmployee from "../../../components/EditForm/FormItemEmployee";
import FormItemNumber from "../../../components/EditForm/FormItemNumber";
import FormItemText from "../../../components/EditForm/FormItemText";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Spinner from "../../../components/Spinner/Spinner";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Role from "../../../enums/Role";
import Unit from "../../../enums/Unit";
import calculatePrice from "../../../functions/calculatePrice";
import useMountEffect from "../../../hooks/useMountEffect";
import usePreload from "../../../hooks/usePreload";
import useUserPersmissions from "../../../hooks/useUserPersmissions";
import {i18n} from "../../../I18n/I18n";
import {filterActive, findById} from "../../../utils/ArrayUtil";
import {formatDate, getYearMonth} from "../../../utils/DateUtils";
import {isNumeric, NumberUtils} from "../../../utils/NumberUtils";
import MonthlockService from "../../Timereg/MonthlockService";
import TimeregarticleService from "../../Timereg/TimeregarticleService";

const ProjectSummaryTimeregarticleEditDialog = props => {
    const context = useContext(AppContext)
    const userPersmissions = useUserPersmissions()

    const {
        project,
        model,
        onSave,
        onHide
    } = props

    const [monthLocked, setMonthLocked] = useState()

    const preload = usePreload()
    const articleList = preload.getArticleList()

    const defaultValues = {
        company: context.currentCompany.id,
        project: project.id,
        date: formatDate(),
        quantity: 0,
        aprice: 0,
        discount: 0,
        price: 0,
        employeeComment: '',
        notarized: true
    }

    function isLocked() {
        return !!model?.locked
    }

    function isInvoiced() {
        return !!model?.invoiced
    }

    const disabled = !userPersmissions.hasRole(Role.TIMEREG_WRITE) ||
        (!userPersmissions.hasRole(Role.TIMEREG_UNLOCK_DAY) && isLocked()) ||
        isInvoiced()


    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, setValue, control} = form

    const watchDate = useWatch({control, name: 'date'})


    useMountEffect(() => {
        async function load() {

            if (model?.id > 0) {
                showLoading()
                reset(await TimeregarticleService.findById(model.id))
                hideLoading()
            }
        }

        load()
    })

    useEffect(() => {
        async function load() {
            showLoading()
            setMonthLocked(await MonthlockService.isLocked(getYearMonth(watchDate)))
            hideLoading()
        }

        load()
    }, [watchDate]);


    const onSubmit = async () => {
        const data = getValues();
        showLoading()

        if (!isNumeric(data.quantity)) data.quantity = 0
        if (!isNumeric(data.aprice)) data.aprice = 0
        if (!isNumeric(data.discount)) data.discount = 0

        await TimeregarticleService.update(data)
        hideLoading()
        onSave()
        onHide()
    }


    function onRemove(e) {
        confirmYes(async () => {
            showLoading()
            await TimeregarticleService.delete(getValues())
            hideLoading()
            onSave()
            onHide()
        })
    }

    if (monthLocked === undefined) return <Spinner/>

    function onArticleChange(e) {
        const article = findById(articleList, e.target.value)
        if (article) {
            setValue('name', article.name)
            setValue('unit', article.unit)
            setValue('aprice', article.price)
        }
    }

    const getPrice = r => {
        return calculatePrice(r.quantity, r.aprice, r.discount)
    }

    const onQuatityOrApriceOrDiscountChange = () => {
        const item = {
            quantity: Number(getValues('quantity')),
            aprice: Number(getValues('aprice')),
            discount: Number(getValues('discount'))
        }
        setValue('price', getPrice(item))
    }

    const onPriceChange = e => {
        const price = NumberUtils.round(Number(e.target.value), 2)
        const quantity = 1
        const discount = Number(getValues('discount'))
        const aprice = NumberUtils.round(price / (1 - discount / 100), 2)

        setValue('quantity', quantity)
        setValue('aprice', aprice)
        setValue('discount', discount)
    }

    return <XlnzDialog headerI18n='article'
                       onHide={props.onHide}
                       buttons={props => [
                           monthLocked || disabled ? undefined :
                               <SaveButton key='save' onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>,
                           monthLocked || !model || disabled || isInvoiced() ? undefined :
                               <RemoveButton key='remove' onClick={onRemove}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >
        <div className='EditForm' style={{width: '900px'}}>
            <form>
                {
                    isInvoiced() &&
                    <h3 style={{color: 'red'}}>{i18n('invoiced')}</h3>
                }
                <div className='columnContainer'>
                    <div className='column'>
                        <div>
                            <FormItemDate name='date' labelI18n='date'
                                          {...form}
                                          disabled={model?.id > 0} // Must be disabled. A timereg belongs to a dayplanning
                            />
                            {
                                monthLocked &&
                                <div style={{color: 'red'}}>{i18n('month_is_locked')}</div>
                            }
                        </div>

                        <FormItemEmployee name='employee' labelI18n='employee'
                                          showSelect={true}
                                          {...form} disabled={disabled || isInvoiced()}/>

                        <FormItemDropdown name='article' labelI18n='article'
                                          options={filterActive(articleList)}
                                          optionLabel='label'
                                          showSelect={true}
                                          onChange={onArticleChange}
                                          {...form} />
                    </div>
                    <div className='column'>
                        <FormItemText name='name' labelI18n='description'
                                      required
                                      maxLength={1000} {...form}/>

                        <FormItemDropdown name='unit' labelI18n='unit' options={Unit.values()} {...form} />
                        <div style={{display: 'flex', gap: '10px'}}>
                            <FormItemNumber name='quantity' labelI18n='quantity' maxWidth={100}
                                            onKeyUp={onQuatityOrApriceOrDiscountChange}
                                            {...form} disabled={disabled || isInvoiced()}/>
                            <FormItemNumber name='aprice' labelI18n='aprice' maxWidth={100}
                                            onKeyUp={onQuatityOrApriceOrDiscountChange}
                                            {...form} disabled={disabled || isInvoiced()}/>
                        </div>
                        <div style={{display: 'flex', gap: '10px'}}>
                            <FormItemNumber name='discount' labelI18n='discount' maxWidth={100}
                                            onKeyUp={onQuatityOrApriceOrDiscountChange}
                                            {...form} disabled={disabled || isInvoiced()}/>
                            <FormItemNumber name='price' labelI18n='price' maxWidth={100}
                                            onKeyUp={onPriceChange}
                                            {...form} disabled={disabled || isInvoiced()}/>
                        </div>
                    </div>
                    <div className='column'>
                        <FormItemCheckbox name='notarized' labelI18n='notarized' {...form}/>
                    </div>
                </div>
            </form>
        </div>
    </XlnzDialog>
}
export default ProjectSummaryTimeregarticleEditDialog;