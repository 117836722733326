import React from 'react';
import {todayDate} from "../../utils/DateUtils";
import Checkbox from "../EditForm/Checkbox";
import DatePicker from "./DatePicker";
import './OptionalDatePicker.scss'

const OptionalDatePicker = props => {
    const {
        value,
        disabled,
        onChange,
        showIcon,
        minDate,
        showAddDays
    } = props

    return <div className='OptionalDatePicker'>
        <Checkbox checked={typeof value === 'string'}
                  onChange={() => {
                      if (!value) {
                          onChange(todayDate())
                      } else {
                          onChange(null)
                      }
                  }}
                  disabled={disabled}
                  style={{margin: '4px 5px 4px 0'}}
        />

        <div style={{visibility: value ? "visible" : "hidden"}}>
            <DatePicker value={value}
                        onChange={onChange}
                        showIcon={showIcon}
                        minDate={minDate}
                        showAddDays={showAddDays}
                        disabled={disabled}
            />
        </div>
    </div>
}
export default OptionalDatePicker;
