import {faAdd, faArrowLeft, faArrowRight, faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getMonth} from "date-fns";
import {range} from "lodash";
import React, {useEffect, useRef, useState} from 'react';
import ReactDatePicker from 'react-datepicker'
import useMountEffect from "../../hooks/useMountEffect";
import {i18n} from "../../I18n/I18n";
import {
    addDays,
    formatDate,
    getDateObject,
    getDaysBetween,
    getFirstDateOfMonth,
    getHolidayName,
    getLastDateOfMonth,
    getWeek,
    getYear,
    MONTHS_I18N_KEYS,
    todayYear
} from "../../utils/DateUtils";
import './DatePicker.scss'
import "react-datepicker/dist/react-datepicker.css";
import {isNumeric} from "../../utils/NumberUtils";
import Button from "../Buttons/Button";
import {useFilter} from "../Grid/filter";

const DatePicker = props => {
    const {
        value,
        disabled,
        onChange,
        showIcon = true,
        minDate,
        maxDate = new Date(9999, 12, 31),
        openOnFocus = false,
        showAddDays = false,
        weekPicker = false
    } = props

    const filter = useFilter('DatePicker', [
        {name: 'daysToAdd', defaultValue: 0}
    ])

    const ref = useRef()
    const [holidays, setHolidays] = useState()

    const onDateChange = date => {
        if (date) onChange(formatDate(date))
    }

    const endYear = todayYear() + 1
    const years = range(1900, endYear + 1)
    const months = MONTHS_I18N_KEYS.map(item => i18n(item))

    // const width = showIcon ? '100px' : '80px'
    const width = weekPicker ? '110px' : (showIcon ? '110px' : '80px')

    useEffect(() => {
        if (openOnFocus) ref?.current?.setOpen(true)
    }, [ref, openOnFocus]);

    function updateHolidays(date) {
        const _holidays = []

        const startDate = getFirstDateOfMonth(date)
        const endDate = getLastDateOfMonth(date)
        // console.log("Loading holidays for ", startDate, endDate)
        for (let i = 0; i < getDaysBetween(startDate, endDate); i++) {
            const date = addDays(startDate, i)
            const holidayName = getHolidayName(date, true)
            if (holidayName) {
                _holidays.push({
                    date: date,
                    holidayName: holidayName
                })
            }
        }
        setHolidays(_holidays)
    }

    useMountEffect(() => {
        updateHolidays(getDateObject(value))
    })

    function getDaysToAdd() {
        const daysToAdd = Number(filter.daysToAdd)
        if (isNumeric(daysToAdd)) return daysToAdd
        else return 0
    }

    const CustomWeekNumberInput = React.forwardRef(({onClick}, ref) => {
        const weekNumber = getWeek(value);
        const year = getYear(value);
        const str = i18n('week') + ' ' + weekNumber + ', ' + year;

        return <button className="custom-week-number-input" onClick={onClick} ref={ref}>
            {str}
        </button>
    })

    return <div className='DatePicker' style={{width: width}}>
        <ReactDatePicker
            ref={ref}
            disabled={disabled}
            dateFormat={weekPicker ? 'I/R' : 'yyyy-MM-dd'}
            locale="el"
            showWeekNumbers
            showWeekPicker={weekPicker}
            customInput={weekPicker ? <CustomWeekNumberInput/> : null}

            holidays={holidays}

            // showIcon={showIcon !== undefined ? showIcon : true}
            toggleCalendarOnIconClick
            selected={getDateObject(value)}
            onChange={onDateChange}
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate}

            onMonthChange={date => updateHolidays(date)}
            onYearChange={date => updateHolidays(date)}

            popperPlacement="bottom-start"

            renderCustomHeader={({
                                     date,
                                     changeYear,
                                     changeMonth,
                                     decreaseMonth,
                                     increaseMonth,
                                     prevMonthButtonDisabled,
                                     nextMonthButtonDisabled,
                                 }) => (
                <div className='header'>
                    <div className='header-month'>
                        <Button faicon={faArrowLeft}
                                disabled={prevMonthButtonDisabled}
                                onClick={e => {
                                    e.preventDefault()
                                    decreaseMonth(e)
                                }}/>

                        <select
                            value={getYear(date)}
                            onChange={e => changeYear(e.target.value)}
                        >
                            {years.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <select
                            value={months[getMonth(date)]}
                            onChange={({target: {value}}) => {
                                changeMonth(months.indexOf(value))
                            }}
                        >
                            {months.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <Button faicon={faArrowRight}
                                disabled={nextMonthButtonDisabled}
                                onClick={e => {
                                    e.preventDefault()
                                    increaseMonth(e)
                                }}/>
                    </div>
                    {
                        showAddDays &&
                        <div className='header-add'>
                            <FontAwesomeIcon icon={faAdd} style={{fontSize: '16px'}}/>
                            <input value={filter.daysToAdd}
                                   onChange={e => filter.update('daysToAdd', e.target.value)}
                                   style={{
                                       width: '35px',
                                       textAlign: 'center'
                                   }}
                            />
                            <Button
                                label={formatDate(addDays(value, getDaysToAdd()))}
                                onClick={() => {
                                    onDateChange(addDays(value, getDaysToAdd()))
                                    ref.current.setOpen(false)
                                }}/>
                        </div>
                    }
                </div>
            )}
        />

        {
            showIcon &&
            <Button faicon={faCalendarAlt}
                    disabled={disabled}
                    onClick={e => {
                        ref?.current?.setOpen(true)
                    }}
                    className='icon'
            />
        }

        {/*<Calendar*/}
        {/*    ref={ref}*/}
        {/*    value={getDateObject(value)}*/}
        {/*    placeholder={value}*/}
        {/*    disabled={disabled}*/}
        {/*    onChange={(e) => onDateChange(e.value)}*/}
        {/*    pattern="yyyy-MM-dd"*/}
        {/*    showIcon={showIcon !== undefined ? showIcon : true}*/}
        {/*    showWeek*/}
        {/*    minDate={minDate ? new Date(minDate) : undefined}*/}
        {/*    yearRange={'1900:' + endYear}*/}
        {/*    style={{width: width}}*/}
        {/*    autoFocus={true}*/}
        {/*    showOnFocus={true}*/}
        {/*/>*/}
    </div>
}
export default DatePicker
