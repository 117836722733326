import {faPlus} from "@fortawesome/free-solid-svg-icons";
import React from 'react';
import {useForm} from "react-hook-form";
import Button, {CancelButton, SendButton} from "../../../components/Buttons/Button";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import {GridTemplates} from "../../../GridTemplates";

const SupplierQnamessageGrid = props => {
    const {
        value,
        getValues,
        disabled,
        dialog,
        handleSubmit,
        save,
        loadData
    } = props

    // const fireChange = (list) => {
    // props.onChange(getSlicedList(list));
    // }
    const columns = [
        {field: 'ctime', headerI18n: 'created', body: GridTemplates.time, width: 140},
        {field: 'senderName', headerI18n: 'sender', width: 160},
        {field: 'message', headerI18n: 'message'},
    ]

    return <div className='formItem'>
        <Grid forceDesktop={true}
              disabled={disabled}
              columns={columns}
              value={value}
              sortable={false}
              scrollable={false}
        />
        <div style={{marginTop: '15px'}}>
            <Button faicon={faPlus}
                    onClick={handleSubmit(async data => {
                        if (!data.id) {
                            showLoading()
                            await save(data)
                            hideLoading()
                        }
                        dialog.show(<AddMessageDialog
                            onSend={async message => {
                                showLoading()
                                const qnaId = getValues('id')
                                // await SUpplierQnaService.insertMessage(qnaId, message)
                                hideLoading()
                                await loadData()
                            }}
                        />);

                    })}
                    style={{backgroundColor: '#699b2c', color: '#fff'}}
            />
        </div>
    </div>
}

export default SupplierQnamessageGrid;


function AddMessageDialog({message, onSend, onHide}) {
    const defaultValues = {
        message: message
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {formState, handleSubmit} = form

    const onSubmit = data => {
        onSend(data.message)
        onHide()
    }

    return <XlnzDialog headerI18n='new_message'
                       onHide={onHide}
                       buttons={() => [
                           <SendButton key='send' labelI18n='send'
                                       disabled={formState.isSubmitting}
                                       onClick={handleSubmit(onSubmit)}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >

        <div className='EditForm' style={{width: '500px', margin: '0'}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormItemTextArea name='message' labelI18n='message' {...form}/>
            </form>
        </div>
    </XlnzDialog>
}
