import SupplierQnas from "./Qnas";

export const modulesSupplier = [
    SupplierQnas
];

export const registerModulesSupplier = [];

export const settingModulesSupplier = [];

export const allSupplierModules = [...modulesSupplier, ...registerModulesSupplier, ...settingModulesSupplier]

export function getModuleSupplier(path) {
    path = path.split("?")[0];
    let m = allSupplierModules.find(m => m.routeProps.path === path);
    if (!m) {
        m = allSupplierModules
            .filter(m => m.routeProps.items?.length > 0)
            .flatMap(m => m.routeProps.items)
            .find(i => i.path === path);
    }
    return m;
}