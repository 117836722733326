import {groupBy} from "lodash";
import {useMemo} from "react";
import Role from "../enums/Role";
import AccountService from "../modules/Accounts/AccountService";
import ArticleService from "../modules/Articles/ArticleService";
import CostcenterService from "../modules/Costcenters/CostcenterService";
import CustomerService from "../modules/Customers/CustomerService";
import DocumentService from "../modules/Documents/DocumentService";
import EmployeeService from "../modules/Employees/EmployeeService";
import HyrletService from "../modules/Hyrlet/HyrletService";
import PricelistService from "../modules/Pricelists/PricelistService";
import SupplierService from "../modules/Suppliers/SupplierService";
import TimeregabsencetypeService from "../modules/Timeregabsencetypes/TimeregabsencetypeService";
import TimeregallowancetypeService from "../modules/Timeregallowancetypes/TimeregallowancetypeService";
import TimeregdebittypeService from "../modules/Timeregdebittypes/TimeregdebittypeService";
import TimeregsalarytypeService from "../modules/Timeregsalarytypes/TimeregsalarytypeService";
import TrainingtypeService from "../modules/Trainingtypes/TrainingtypeService";
import UsergroupService from "../modules/Usergroups/UsergroupService";
import {filterActive} from "../utils/ArrayUtil";
import {deepFreezeObject} from "../utils/ObjectUtils";
import useUserPersmissions from "./useUserPersmissions";

export const PreloadType = {
    ACCOUNT_LIST: "accountList",
    ARTICLE_LIST: "articleList",
    COSTCENTER_LIST: "costcenterList",
    CUSTOMER_LIST: "customerList",
    DOCUMENT_LIST: "documentList",
    EMPLOYEE_LIST: "employeeList",
    PRICELIST_LIST: "pricelistList",
    SUPPLIER_LIST: "supplierList",
    TIMEREGABSENCETYPE_LIST: "timeregabsencetypeList",
    TIMEREGALLOWANCETYPE_LIST: "timeregallowancetypeList",
    TIMEREGDEBITTYPE_LIST: "timeregdebittypeList",
    TIMEREGSALARYTYPE_LIST: "timeregsalarytypeList",
    TRAININGTYPE_LIST: "trainingtypeList",

    USERGROUP_LIST: "usergroupList",

    HYRLET_LOGIN_INFO: "hyrletLoginInfo",
}

const LOADERS = [
    {name: PreloadType.ACCOUNT_LIST, loadFunction: AccountService.findAll, role: Role.INVOICE_READ},
    {name: PreloadType.ARTICLE_LIST, loadFunction: ArticleService.findAll, role: Role.ARTICLE_READ},
    {name: PreloadType.COSTCENTER_LIST, loadFunction: CostcenterService.findAll, role: Role.CUSTOMER_READ},
    {name: PreloadType.CUSTOMER_LIST, loadFunction: CustomerService.findAll, role: Role.CUSTOMER_READ},
    {name: PreloadType.DOCUMENT_LIST, loadFunction: DocumentService.findAll, role: Role.DOCUMENT_READ},
    {
        name: PreloadType.EMPLOYEE_LIST,
        loadFunction: EmployeeService.findAll,
        postLoadFunction: (employeeList) => {
            employeeList.forEach(item => item.alias = getEmployeeLabel(employeeList, item))
        },
        role: Role.EMPLOYEE_READ
    },
    {name: PreloadType.PRICELIST_LIST, loadFunction: PricelistService.findAll, role: Role.CUSTOMER_READ},
    {name: PreloadType.SUPPLIER_LIST, loadFunction: SupplierService.findAll, role: Role.SUPPLIER_READ},
    {
        name: PreloadType.TIMEREGABSENCETYPE_LIST,
        loadFunction: TimeregabsencetypeService.findAll,
        role: Role.USER
    },
    {
        name: PreloadType.TIMEREGALLOWANCETYPE_LIST,
        loadFunction: TimeregallowancetypeService.findAll,
        role: Role.USER
    },
    {name: PreloadType.TIMEREGDEBITTYPE_LIST, loadFunction: TimeregdebittypeService.findAll, role: Role.USER},
    {name: PreloadType.TIMEREGSALARYTYPE_LIST, loadFunction: TimeregsalarytypeService.findAll, role: Role.USER},
    {name: PreloadType.TRAININGTYPE_LIST, loadFunction: TrainingtypeService.findAll, role: Role.USER},

    {name: PreloadType.USERGROUP_LIST, loadFunction: UsergroupService.findAll, role: Role.USERGROUP_READ},

    {name: PreloadType.HYRLET_LOGIN_INFO, loadFunction: HyrletService.getLoginInfo, role: Role.HYRLET},
]

function getEmployeeLabel(employeeList, employee) {
    const labelFuncs = [
        item => item.firstName,
        item => item.firstName + ' ' + item.lastName[0],
        item => item.firstName + ' ' + item.lastName,
        item => item.firstName + ' ' + item.lastName + ' (' + (item.number || '-') + ')'
    ]

    if (employee.active) {
        for (const labelFunc of labelFuncs) {
            const label = labelFunc(employee)
            const group = groupBy(filterActive(employeeList), labelFunc);
            if (group[label]?.length === 1) {
                return label
            }
        }
    } else {
        return labelFuncs[labelFuncs.length - 1](employee)
    }
    return null
}


const PRELOAD_MAP = new Map()

export default function usePreload() {
    const userPersmissions = useUserPersmissions()

    const result = useMemo(() => ({
        load: async (preloadType) => {
            const loader = LOADERS.find(item => item.name === preloadType)
            const _resp = await loader.loadFunction()
            if (loader.postLoadFunction) loader.postLoadFunction(_resp)
            PRELOAD_MAP.set(preloadType, deepFreezeObject(_resp))
            return _resp
        },
        loadAll: async () => {
            const _loaders = LOADERS.filter(item => !item.role || userPersmissions.hasRole(item.role))

            const _resp = await Promise.all(_loaders.map(item => item.loadFunction()))

            for (let i = 0; i < _loaders.length; i++) {
                const _loader = _loaders[i]
                if (_loader.postLoadFunction) _loader.postLoadFunction(_resp[i])
                PRELOAD_MAP.set(_loader.name, deepFreezeObject(_resp[i]))
            }
        },
        get: preloadType => PRELOAD_MAP.get(preloadType),

        getAccountList: () => PRELOAD_MAP.get(PreloadType.ACCOUNT_LIST),
        getArticleList: () => PRELOAD_MAP.get(PreloadType.ARTICLE_LIST),
        getCostcenterList: () => PRELOAD_MAP.get(PreloadType.COSTCENTER_LIST),
        getCustomerList: () => PRELOAD_MAP.get(PreloadType.CUSTOMER_LIST),
        getDocumentList: () => PRELOAD_MAP.get(PreloadType.DOCUMENT_LIST),
        getEmployeeList: () => PRELOAD_MAP.get(PreloadType.EMPLOYEE_LIST),
        getPricelistList: () => PRELOAD_MAP.get(PreloadType.PRICELIST_LIST),
        getSupplierList: () => PRELOAD_MAP.get(PreloadType.SUPPLIER_LIST),
        getTimeregabsencetypeList: () => PRELOAD_MAP.get(PreloadType.TIMEREGABSENCETYPE_LIST),
        getTimeregallowancetypeList: () => PRELOAD_MAP.get(PreloadType.TIMEREGALLOWANCETYPE_LIST),
        getTimeregdebittypeList: () => PRELOAD_MAP.get(PreloadType.TIMEREGDEBITTYPE_LIST),
        getTimeregsalarytypeList: () => PRELOAD_MAP.get(PreloadType.TIMEREGSALARYTYPE_LIST),
        getTrainingtypeList: () => PRELOAD_MAP.get(PreloadType.TRAININGTYPE_LIST),

        getUsergroupList: () => PRELOAD_MAP.get(PreloadType.USERGROUP_LIST),

        gethyrletLoginInfo: () => PRELOAD_MAP.get(PreloadType.HYRLET_LOGIN_INFO),
    }), [userPersmissions])


    return result
    // return {
    //     load: async (preloadType) => {
    //         const loader = LOADERS.find(item => item.name === preloadType)
    //         const _resp = await loader.loadFunction()
    //         if (loader.postLoadFunction) loader.postLoadFunction(_resp)
    //         PRELOAD_MAP.set(preloadType, deepFreezeObject(_resp))
    //         return _resp
    //     },
    //     loadAll: async () => {
    //         const _loaders = LOADERS.filter(item => !item.role || roles.hasRole(item.role))
    //
    //         const _resp = await Promise.all(_loaders.map(item => item.loadFunction()))
    //
    //         for (let i = 0; i < _loaders.length; i++) {
    //             const _loader = _loaders[i]
    //             if (_loader.postLoadFunction) _loader.postLoadFunction(_resp[i])
    //             PRELOAD_MAP.set(_loader.name, deepFreezeObject(_resp[i]))
    //         }
    //     },
    //     get: preloadType => PRELOAD_MAP.get(preloadType),
    //
    //     getAccountList: () => PRELOAD_MAP.get(PreloadType.ACCOUNT_LIST),
    //     getArticleList: () => PRELOAD_MAP.get(PreloadType.ARTICLE_LIST),
    //     getCostcenterList: () => PRELOAD_MAP.get(PreloadType.COSTCENTER_LIST),
    //     getCustomerList: () => PRELOAD_MAP.get(PreloadType.CUSTOMER_LIST),
    //     getDocumentList: () => PRELOAD_MAP.get(PreloadType.DOCUMENT_LIST),
    //     getEmployeeList: () => PRELOAD_MAP.get(PreloadType.EMPLOYEE_LIST),
    //     getPricelistList: () => PRELOAD_MAP.get(PreloadType.PRICELIST_LIST),
    //     getTimeregabsencetypeList: () => PRELOAD_MAP.get(PreloadType.TIMEREGABSENCETYPE_LIST),
    //     getTimeregdebittypeList: () => PRELOAD_MAP.get(PreloadType.TIMEREGDEBITTYPE_LIST),
    //     getTimeregsalarytypeList: () => PRELOAD_MAP.get(PreloadType.TIMEREGSALARYTYPE_LIST),
    //
    //     gethyrletLoginInfo: () => PRELOAD_MAP.get(PreloadType.HYRLET_LOGIN_INFO),
    // }
}