import React, {useState} from "react";
import {CancelButton, FetchButton, MainButton} from "../../../components/Buttons/Button";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Vat from "../../../enums/Vat";
import {GridTemplates} from "../../../GridTemplates";
import useDepsEffect from "../../../hooks/useEffect";
import {i18n} from "../../../I18n/I18n";
import ArrayUtil from "../../../utils/ArrayUtil";
import {getDaysBetween, getDaysInMonth, getMonth, getMonthName, getYear, getYearMonth} from "../../../utils/DateUtils";
import {NumberUtils} from "../../../utils/NumberUtils";
import {deepCopy} from "../../../utils/ObjectUtils";
import AgreementService from "../../Agreements/AgreementService";

export const FetchAgreementrowsButton = props => {
    const {
        invoiceDate,
        customer,
        rowList,
        accountList,
        onAddRows
    } = props

    const [uninvoicePrice, setUninvoicePrice] = useState(0)
    const [allAgreementrowList, setAllAgreementrowList] = useState(0)
    const [agreementrowList, setAgreementrowList] = useState(0)
    const [showFetchHoursDialog, setShowFetchHoursDialog] = useState(false)

    // When project is changed
    useDepsEffect(() => {
        if (invoiceDate && customer && customer.id > 0) {
            const year = getYear(invoiceDate)
            const month = getMonth(invoiceDate)

            AgreementService.findRowsByCustomer(customer.id, year, month).then(_agreementrowList => {
                _agreementrowList = filterAgreementrowsInOtherInvoices(_agreementrowList)
                setAllAgreementrowList(_agreementrowList)
            })
        }
    }, [invoiceDate, customer?.id])

    // When invoice rows are changed
    useDepsEffect(() => {
        if (allAgreementrowList && customer && customer.id > 0) {
            let _agreementrowList = deepCopy(allAgreementrowList)

            decreaseQuantityOfAlreadyInvoicedRows(_agreementrowList)
            decreaseQuantityOfUnsavedRowsInThisInvoice(_agreementrowList)
            calculateLeftToInvoice(_agreementrowList)

            setAgreementrowList(_agreementrowList)
            setUninvoicePrice(ArrayUtil.sum(_agreementrowList, 'leftToInvoice'))
        }
    }, [allAgreementrowList, rowList])

    const filterAgreementrowsInOtherInvoices = agreementrowList => {
        agreementrowList.forEach(tr => {
            tr.leftToInvoice = tr.price

            const proposalIrtrList = tr.invoicerowagreementrowList
            proposalIrtrList.forEach(proposalIrtr => {
                if (proposalIrtr.invoicerow > 0 && !isInThisInvoice(proposalIrtr)) {
                    tr.leftToInvoice -= proposalIrtr.price
                }
            })
        })

        return agreementrowList
    }

    function isInThisInvoice(proposalIrtr) {
        return getInvoiceIrtrList().filter(invoiceIrtr => proposalIrtr.invoicerow === invoiceIrtr.invoicerow &&
            proposalIrtr.agreementrow === invoiceIrtr.agreementrow).length > 0
    }

    function getInvoiceIrtrList() {
        return rowList
            .flatMap(r => r.agreementrowList)
            .filter(invoiceIrtr => invoiceIrtr !== undefined)
    }

    function decreaseQuantityOfAlreadyInvoicedRows(_agreementrowList) {
        _agreementrowList.forEach(tr => {
            const _tr = tr
            const proposalIrtrList = _tr.invoicerowagreementrowList
            proposalIrtrList.forEach(proposalIrtr => {
                getInvoiceIrtrList().forEach(invoiceIrtr => {
                    if (proposalIrtr.invoicerow === invoiceIrtr.invoicerow
                        && proposalIrtr.agreementrow === invoiceIrtr.agreementrow) {
                        _tr.leftToInvoice -= invoiceIrtr.price
                    }
                })
            })
        })
    }

    function decreaseQuantityOfUnsavedRowsInThisInvoice(_agreementrowList) {
        getInvoiceIrtrList().forEach(invoiceIrtr => {
            if (invoiceIrtr.invoicerow === 0) {
                const _tr = ArrayUtil.findById(_agreementrowList, invoiceIrtr.agreementrow)
                _tr.leftToInvoice -= invoiceIrtr.price
            }
        })
    }

    function calculateLeftToInvoice(agreementrowList) {
        // agreementrowList.forEach(_tr => _tr.leftToInvoice = _tr.leftToInvoice * _tr.aprice)
        agreementrowList.forEach(_tr => _tr.priceToInvoice = 0)
    }

    const onSave = (agreementrowList) => {
        const invoiceRows = []
        const account = accountList.find(a => a.number === 3010)
        // console.log("accountList", accountList, account)
        invoiceRows.push({
            headline: true,
            onlyTotal: true,
            name: 'Licensavgift för Verktyg1 - ' + getMonthName(invoiceDate) + ' ' + getYear(invoiceDate),
            quantity: 0,
            aprice: 0,
            discount: 0,
            price: 0,
            vat: 0
        })

        agreementrowList.forEach(ar => {
            const invoicerowagreementrowList = []
            invoicerowagreementrowList.push({
                invoicerow: 0,
                agreementrow: ar.id,
                yearMonth: getYearMonth(invoiceDate),
                price: ar.leftToInvoice
            })

            let dateInfo = ''
            if (getDaysBetween(ar.startDate, ar.endDate) !== getDaysInMonth(ar.startDate)) {
                dateInfo = ' ' + ar.startDate + ' - ' + ar.endDate
            }

            invoiceRows.push({
                headline: false,
                onlyTotal: true,
                name: ('#' + ar.num + ' ' + ar.userName + dateInfo),
                quantity: 1,
                aprice: ar.leftToInvoice,
                discount: 0,
                price: ar.leftToInvoice,
                vat: Vat.VAT25.id,
                account: account?.id,
                agreementrowList: invoicerowagreementrowList
            })
        })

        invoiceRows.push({
            headline: false,
            onlyTotal: true,
            name: '',
            quantity: 0,
            aprice: 0,
            discount: 0,
            price: 0,
            vat: 0
        })

        // invoiceRows.push({
        //     headline: true,
        //     onlyTotal: true,
        //     name: 'Användarvillkor för Verktyg1:',
        //     quantity: 0,
        //     aprice: 0,
        //     discount: 0,
        //     price: 0,
        //     vat: 0
        // })

        invoiceRows.push({
            headline: false,
            onlyTotal: true,
            name: 'Genom betalning av denna faktura bekräftas att våra användarvillkor accepteras.' +
                ' Villkoren finns tillgängliga på: https://www.verktyg1.se/anvandarvillkor',
            quantity: 0,
            aprice: 0,
            discount: 0,
            price: 0,
            vat: 0
        })

        onAddRows(invoiceRows)

        setShowFetchHoursDialog(false)
    }

    return <div>
        {
            uninvoicePrice !== 0 &&
            <FetchButton
                label={i18n('agreement') + ' (' + NumberUtils.formatCurrency(uninvoicePrice) + ')'}
                onClick={e => setShowFetchHoursDialog(true)}
                style={{marginRight: '20px'}}
            />
        }
        {
            showFetchHoursDialog &&
            <FetchAgreementrowsDialog
                agreementrowList={agreementrowList}
                onSave={onSave}
                onHide={() => setShowFetchHoursDialog(false)}/>
        }
    </div>
}

const FetchAgreementrowsDialog = props => {
    const {
        agreementrowList,
        onSave
    } = props
    const [selection, setSelection] = useState([])

    const onSubmit = () => {
        onSave(selection)
    }

    const columns = [
        {field: 'userName', headerI18n: 'user'},
        {
            field: 'leftToInvoice', headerI18n: 'price', body: GridTemplates.currencyTwoDecimals, align: 'right',
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            width: 100
        },
        {field: 'selection', selectionMode: 'multiple', width: 40},
    ]


    return <XlnzDialog headerI18n='agreement'
                       onHide={props.onHide}
                       buttons={() => [
                           <MainButton key='do_invoice' labelI18n='do_invoice' onClick={e => onSubmit()}/>,
                           <CancelButton key='cancel' onClick={e => props.onHide()}/>
                       ]}
    >
        <div style={{width: '400px'}}>
            <Grid forceDesktop={true}
                  columns={columns}
                  value={agreementrowList}
                  heightOffset={370}
                  rowClassName={e => ({'bold': e.headline})}
                  selection={selection}
                  onSelectionChange={e => setSelection(e.value)}
            />
        </div>
    </XlnzDialog>
}