import '@components/EditForm/EditForm.scss'
import React, {useState} from 'react';
import {Controller} from "react-hook-form";
import FormItemCustomerAndContact from "../../../components/EditForm/FormItemCustomerAndContact";
import FormItemDate from "../../../components/EditForm/FormItemDate";
import FormItemOptionalDate from "../../../components/EditForm/FormItemOptionalDate";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import SimpleEditForm from "../../../components/EditForm/SimpleEditForm";
import {confirm} from "../../../components/Overlay/Confirm";
import TabPanel from "../../../components/TabView/TabPanel";
import Role from "../../../enums/Role";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import {hasDuplicates} from "../../../utils/ArrayUtil";
import {todayDate} from "../../../utils/DateUtils";
import UserService from "../../Users/UserService";
import AgreementService from "../AgreementService";
import AgreementrowGrid from "./AgreementrowGrid";

const AgreementEditForm = () => {
    const preload = usePreload()
    const [common, setCommon] = useState();

    const onFormCreated = async _form => {
        const [
            _userList
        ] = await Promise.all([
            UserService.findAllUsers()
        ])

        setCommon({
            customerList: preload.get(PreloadType.CUSTOMER_LIST),
            userList: _userList
        })
    }

    function preSaveFunction(data) {
        return new Promise((resolve) => {
            if (hasDuplicates(data.rowList)) {
                confirm({
                    header: 'Fråga',
                    message: 'Det finns dubbletter, vill du fortsätta?',
                    options: [
                        {
                            label: 'Ja',
                            onClick: () => resolve(undefined) // Användaren vill fortsätta
                        },
                        {
                            label: 'Nej',
                            onClick: () => resolve("Operationen avbröts") // Användaren avbryter
                        }
                    ]
                });
            } else {
                resolve(undefined); // Inga dubbletter, fortsätt direkt
            }
        });
    }

    return <SimpleEditForm
        writeRole={Role.AGREEMENT_WRITE}
        headlineI18n='agreement'
        defaultValues={{
            customer: undefined,
            startDate: todayDate(),
            endDate: undefined,
            note: '',
            rowList: []
        }}
        onFormCreated={onFormCreated}
        loadFunction={AgreementService.findById}
        preSaveFunction={preSaveFunction}
        saveFunction={AgreementService.update}
        initLeftFields={form => [
            <FormItemCustomerAndContact key='customer' form={form}/>,
        ]}
        initCenterFields={form => [
            <FormItemDate key='startDate' name='startDate' labelI18n='start_date' required {...form}/>,
            <FormItemOptionalDate key='endDate' name='endDate' labelI18n='end_date' required {...form}/>,
        ]}
        initRightFields={form => [
            <FormItemOptionalDate key='userAgreementApproved' name='userAgreementApproved'
                                  label='Användarvillkor godkända' required {...form}/>,
            <FormItemTextArea key='note' name='note' labelI18n='note' maxLength={5000} {...form}/>,
        ]}
        initTabs={form => [
            <TabPanel key='rowList' headerI18n='rows'>
                <Controller name="rowList"
                            control={form.control}
                            render={({field: {ref, value, onChange}}) =>
                                <AgreementrowGrid
                                    inputRef={ref} value={value} onChange={onChange}
                                    userList={common.userList}
                                />
                            }/>
            </TabPanel>
        ]}
    />
}

export default AgreementEditForm;
