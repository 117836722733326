import React, {useContext, useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import AppContext from "../../../AppContext";
import {CancelButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemDate from "../../../components/EditForm/FormItemDate";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemNumber from "../../../components/EditForm/FormItemNumber";
import FormItemOptionalDate from "../../../components/EditForm/FormItemOptionalDate";
import FormItemProject from "../../../components/EditForm/FormItemProject";
import FormItemText from "../../../components/EditForm/FormItemText";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Spinner from "../../../components/Spinner/Spinner";
import TabPanel from "../../../components/TabView/TabPanel";
import TabView from "../../../components/TabView/TabView";
import BillingType from "../../../enums/BillingType";
import Role from "../../../enums/Role";
import useDialog from "../../../hooks/useDialog";
import useMountEffect from "../../../hooks/useMountEffect";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import ProjectStatus from "../../../modules/Projects/ProjectStatus";
import QnaType from "../../../modules/Projects/Qnas/QnaType";
import {findById} from "../../../utils/ArrayUtil";
import {todayDate} from "../../../utils/DateUtils";
import RouterUtils from "../../../utils/RouterUtils";
import SupplierProjectService from "../../Projects/SupplierProjectService";
import SupplierQnaService from "../SupplierQnaService";
import SupplierQnamessageGrid from "./SupplierQnamessageGrid";


const SupplierQnaEditForm = props => {
    const context = useContext(AppContext);
    const roles = useRoles()
    // const preload = usePreload()
    const dialog = useDialog()

    const disabled = !roles.hasRole(Role.SUPPLIERCONTACT)

    // const location = useLocation()
    const navigate = useNavigate()
    const {id} = useParams()
    const isNew = id === '0'

    const [projectList, setProjectList] = useState()

    // const customerList = preload.getCustomerList()

    const defaultValues = {
        id: 0,
        company: context.currentCompany.id,
        ourReference: 0,
        cuser: context.user.id,
        type: 'QUESTION',
        number: '',
        startDate: todayDate(),
        price: 0,
        note: '',
        messageList: []
    }

    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, setValue, control} = form

    const watchMessageList = useWatch({control, name: 'messageList'})

    const save = async data => {
        console.log("SAVE QNA", data)
        return SupplierQnaService.update(data).then(_model => {
            if (_model) {
                navigate('/projects/qnas/' + _model.id, {replace: true})
                reset(_model)
                return _model
            }
        });
    }

    const onSubmit = () => {
        showLoading();
        const data = getValues();
        return save(data).then(async _model => {
            if (_model) {
                RouterUtils.goBack(navigate)
            }
            hideLoading();
        });
    }

    async function loadData() {
        showLoading();

        let _model = id > 0 ? await SupplierQnaService.findById(id) : undefined;
        const date = _model?.startDate || todayDate()

        const [
            _projectList
        ] = await Promise.all([
            SupplierProjectService.findAll({
                status: ProjectStatus.ONGOING.id,
                startDate: date,
                endDate: date,
                forceId: _model?.project
            })
        ]);

        setProjectList(_projectList);
        reset(_model);
        hideLoading();
    }

    useMountEffect(async () => loadData())

    const onProjectChange = e => {
        const project = findById(projectList, Number(e.target.value))
        setValue('ourReference', project.projectLeader)
        // const customer = findById(customerList, project.customer)
        // setValue('customer', project.customer)
        // setValue('customercontact', project.customercontact)
    }


    if (!projectList) return <Spinner/>
    return (
        <div className="EditForm">
            <div className="headline">
                <h1>{i18n('question_and_answer')} {getValues('number')}</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="button-row">
                    <div className='left-buttons'>
                        <SaveButton onClick={handleSubmit(onSubmit)} disabled={isSubmitting}/>
                        <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                    </div>
                    <div className='divider'></div>
                    <div className='right-buttons'>
                        {/*<DownloadInvoiceButton handleSubmit={handleSubmit} save={save}/>*/}
                    </div>
                </div>

                <div className='columnContainer'>
                    <div className='column'>
                        {
                            isNew &&
                            <FormItemProject options={projectList} onChange={onProjectChange} required {...form}/>
                        }
                        {
                            !isNew &&
                            <FormItemText name='projectNumberAndName' labelI18n='project' {...form} disabled={!isNew}/>
                        }

                        <FormItemDropdown key='type' name='type' label={i18n('unit')}
                                          options={QnaType.values()}
                                          optionLabel='label'
                                          showSelect
                                          width={160}
                                          {...form} disabled={!isNew}/>
                        <FormItemText name='name' labelI18n='subject' maxLength={5000} required
                                      {...form} disabled={!isNew}/>
                    </div>
                    <div className='column'>
                        {
                            !isNew &&
                            <>
                                <FormItemDate name='startDate' labelI18n='start_date' required {...form}
                                              disabled={!isNew}/>
                                <FormItemOptionalDate name='endDate' labelI18n='end_date' {...form} disabled={!isNew}/>
                                <FormItemDropdown key='billingType' name='billingType' labelI18n='billing_type'
                                                  options={BillingType.values()}
                                                  showSelect
                                                  width={160}
                                                  {...form} disabled={!isNew}/>
                                <FormItemNumber name='price' labelI18n='price' required {...form} disabled={!isNew}/>
                            </>
                        }
                    </div>
                    <div className='column'>
                        {
                            !isNew &&
                            <FormItemText name='ourReferenceName' labelI18n='our_reference' disabled={true} {...form}
                                          disabled={!isNew}/>
                        }

                        {/*<FormItemTextArea name='note' labelI18n='note' maxLength={5000} {...form}/>*/}
                    </div>
                </div>

                {
                    isNew &&
                    <FormItemTextArea name='question' labelI18n='question' maxLength={5000} {...form}/>
                }

                {
                    !isNew &&
                    <TabView renderActiveOnly={false}>
                        <TabPanel key='messageList' header={i18n('message') + ' (' + (watchMessageList.length) + ')'}>
                            <Controller name='messageList'
                                        control={control}
                                        render={({field: {ref, value, onChange}}) =>
                                            <SupplierQnamessageGrid inputRef={ref} value={value} onChange={onChange}
                                                                    getValues={getValues}
                                                                    disabled={disabled}
                                                                    dialog={dialog}
                                                                    handleSubmit={handleSubmit}
                                                                    save={save}
                                                                    loadData={loadData}
                                            />
                                        }/>
                        </TabPanel>
                    </TabView>
                }
            </form>
            {dialog.render()}
        </div>
    );
}

export default SupplierQnaEditForm;
