import {faThumbsUp} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import './RecommendVerktyg1.scss'
import {useForm} from "react-hook-form";
import useDialog from "../../hooks/useDialog";
import NetUtils from "../../utils/NetUtils";
import {CancelButton, SendButton} from "../Buttons/Button";
import FormItemCheckbox from "../EditForm/FormItemCheckbox";
import FormItemText from "../EditForm/FormItemText";
import FormItemTextArea from "../EditForm/FormItemTextArea";
import {errorAlert, infoAlert} from "../Overlay/Alert";
import {hideLoading, showLoading} from "../Overlay/Loading";
import Tooltip from "../Overlay/Tooltip";
import XlnzDialog from "../XlnzDialog/XlnzDialog";

export default function RecommendVerktyg1() {
    const dialog = useDialog()

    function onClick() {
        dialog.show(<RecommendVerktyg1Dialog
            onSend={async data => {
                showLoading()
                console.log(data);
                await NetUtils.doPost('/api/recommends/send', data)
                hideLoading()
                infoAlert("Tack för din rekommendation! Vi uppskattar verkligen att du har tagit dig tid att tipsa om Verktyg1. " +
                    "Din rekommendation betyder mycket för oss, och vi är glada över att du vill sprida fördelarna med vår tjänst till andra företag!")
            }}
        />)
    }

    return <>
        <div className='RecommendVerktyg1 user-button header-button-clickable' onClick={onClick}>
            <Tooltip value='Rekommendera Verktyg1!' style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <FontAwesomeIcon icon={faThumbsUp} style={{fontSize: '25px'}}/>
            </Tooltip>
        </div>
        {dialog.render()}
    </>

}


function RecommendVerktyg1Dialog({onSend, onHide}) {
    const form = {...useForm({defaultValues: {}})}
    const {handleSubmit} = form

    const onSubmit = data => {
        if (!data.spoked) {
            errorAlert("Du behöver ha varit i kontakt med företaget och personen du rekommenderar." +
                " Vänligen markera rutan när du gjort detta.")
        } else if (!data.agreeToTerms) {
            errorAlert("Du måste godkänna villkoren." +
                " Vänligen läs igenom 'Förutsättningar & villkor' nedan och markera rutan om du godkänner.")
        } else {
            onSend(data)
            onHide()
        }
    }

    return <XlnzDialog header='Rekommendera Verktyg1!'
                       onHide={onHide}
                       buttons={() => [
                           <SendButton key='send' labelI18n='send'
                                       onClick={handleSubmit(onSubmit)}/>,
                           <CancelButton key='cancel' onClick={e => {
                               console.log("HIDE")
                               return onHide();
                           }}/>
                       ]}
    >
        <div className='RecommendVerktyg1Dialog EditForm' style={{width: '500px', margin: '0'}}>
            <div style={{fontSize: '14px', marginBottom: '10px'}}>
                Tipsa andra företag om Verktyg1 enkelt och smidigt här nedanför.
                Om ditt tips leder till en ny kund, får du <b>20 %</b> av den nya kundens avgift som
                rabatt på dina fakturor varje månad i <b>12 månader</b>!<br/>
            </div>

            <div>
                <FormItemText key='companyName' name='companyName' label='Företaget du vill rekommendera'
                              maxLength={500}
                              required {...form}/>
                <FormItemText key='contactpersonName' name='contactpersonName' label='Kontaktperson på företaget'
                              maxLength={500}
                              required {...form}/>
                <FormItemText key='contactpersonPhone' name='contactpersonPhone' label='Kontaktpersonens telefonnummer'
                              maxLength={500}
                              required {...form}/>
                <FormItemTextArea key='message' name='message' label='Meddelande till Verktyg1'
                              maxLength={5000}
                              required {...form}/>

                <FormItemCheckbox key='spoked' name='spoked'
                                  label='Jag har pratat med den ovanstående personen och rekommenderar att Verktyg1 tar kontakt,
                                  eftersom jag tror att Verktyg1 kan vara en passande lösning för deras behov.'
                                  required {...form}/>

                <FormItemCheckbox key='agreeToTerms' name='agreeToTerms'
                                  label='Jag godkänner villkoren för kundrekommendation och samtycker till att Verktyg1
                                  kontaktar företaget med hänvisning till mig som befintlig Verktyg1-kund.'
                                  required {...form}/>
            </div>

            <h3>Förutsättningar & villkor</h3>
            <ul>
                <li>
                    Om företaget du rekommenderar blir kund hos Verktyg1 inom 6 månader från det att du lämnat
                    rekommendationen, får du 20 % av den nya kundens licensavgift i avdrag på din månadsfaktura.
                </li>
                <li>
                    Om ersättningsbeloppet överstiger din faktura, kommer återstående belopp att dras av från kommande
                    fakturor.
                </li>
                <li>
                    Rekommendationen ger ingen ersättning om Verktyg1 redan vid tidpunkten för tipset har en pågående
                    dialog med det rekommenderade företaget om att bli kund.
                </li>
                <li>
                    Verktyg1 förbehåller sig rätten att avgöra vad som räknas som en rekommendation och om den uppfyller
                    kraven för att ersättning ska betalas ut.
                </li>
            </ul>

        </div>
    </XlnzDialog>
}