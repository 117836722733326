import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import Grid from "../../components/Grid/Grid";
import {infoAlert} from "../../components/Overlay/Alert";
import Tooltip from "../../components/Overlay/Tooltip";
import XlnzCard from "../../components/XlnzCard/XlnzCard";
import {GridTemplates} from "../../GridTemplates";

const ApiCalls = props => {
    const {
        value
    } = props

    const columns = [
        {field: 'id', header: 'ID', width: 80},
        {field: 'time', headerI18n: 'time', body: GridTemplates.time, width: 140},
        {field: 'companyName', headerI18n: 'company', width: 200},
        {field: 'method', header: 'Method', width: 60},
        {
            field: 'responseCode', header: 'Code', body: rowData => {
                return <Tooltip value={rowData.errorMsg}>
                    {rowData.responseCode}
                </Tooltip>
            }, width: 60
        },
        {field: 'requestUrl', header: 'Request URL'},
        {
            field: 'requestBody', header: '', body: rowData => {
                if (rowData.requestBody.length === 0) return undefined
                return <Tooltip value={rowData.requestBody}>
                    <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{cursor: 'pointer', color: '#777', fontSize: '13px'}}
                        onClick={e2 => {
                            e2.stopPropagation()
                            e2.preventDefault()
                            navigator.clipboard.writeText(rowData.requestBody);
                            infoAlert("Copied 'requestBody' to clipboard");
                        }}
                    />
                </Tooltip>
            },
            width: 25
        },
    ]


    return <XlnzCard title='API Calls'>
        <Grid
            scrollable={false}
            columns={columns}
            value={value}
            onRowSelect={row => {
                navigator.clipboard.writeText(row.data.requestUrl);
                infoAlert("Copied 'requestUrl' to clipboard");
            }}
            forceDesktop={true}
        />
    </XlnzCard>

}
export default ApiCalls;