import React, {useCallback, useEffect, useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import MonthDropdown from "../../components/Date/MonthDropdown";
import Label from "../../components/EditForm/Label";
import YearFilter from "../../components/filters/YearFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import PdfColumn from "../../components/Grid/PdfColumn";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import XlnzDropdown from "../../components/XlnzDropdown/XlnzDropdown";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import {match} from "../../functions/match";
import useDialog from "../../hooks/useDialog";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import QnaStatus from "../../modules/Projects/Qnas/QnaStatus";
import QnaType from "../../modules/Projects/Qnas/QnaType";
import {formatTime, todayYear} from "../../utils/DateUtils";
import SupplierQnaService from "./SupplierQnaService";

const SupplierQnaGrid = props => {
    const roles = useRoles()
    const dialog = useDialog()

    const filter = useFilter('QnaGrid', [
        {name: 'status', defaultValue: QnaStatus.ONGOING.id},
        {name: 'year', defaultValue: todayYear()},
        {name: 'month', defaultValue: undefined},
        {name: 'projectLeader', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list?.filter(item => match(filter, item, 'projectLeader'))

    const hasTimefilter = useCallback(() => [
                QnaStatus.COMPLETED.id,
                QnaStatus.CANCELLED.id].indexOf(filter.status) !== -1
            || !filter.status
        , [filter.status])


    const loadData = useCallback(async () => {
        showLoading()
        const params = {
            status: filter.status,
            year: hasTimefilter() ? filter.year : undefined,
            month: hasTimefilter() ? filter.month : undefined,
            search: filter.search
        }
        const _list = await SupplierQnaService.findAll(params)
        _list.forEach(item => {
            item.typeName = QnaType.findById(item.type).label
            // item.statusName = QnaStatus.getName(item.status)
            // if (item.status === QnaStatus.OVERDUE.id) {
            //     const days = getDaysBetween(todayDate(), item.dueDate) - 1
            //     item.statusName += ' (' + days + ' ' + i18n(days === 1 ? 'day' : 'days').toLowerCase() + ')'
            //
            //     if (item.reminderDate) {
            //         item.statusName += '\n' + i18n('reminded') + ' ' + item.reminderDate
            //     }
            // }
            // if (item.paymentDate) item.statusName += ' ' + item.paymentDate
            //
            // item.exportedTimeString = formatTime((item.exportedTime))
        })
        setList(_list);
        hideLoading()

    }, [hasTimefilter, filter.status, filter.year, filter.month, filter.search])

    useEffect(() => {
        loadData()
    }, [loadData])


    const columns = [
        PdfColumn(() => true,
            rowData => rowData.number ? i18n('invoice') + ' ' + rowData.number : '',
            rowData => '/api/qnas/' + rowData.id + '/pdf'
        ),
        {
            field: 'number', headerI18n: 'number',
            tooltip: rowData => i18n('created') + ': ' + formatTime(rowData.ctime),
            width: 100, mobile: true, mobileBold: true
        },
        {field: 'typeName', headerI18n: 'type', mobileWithHeader: true, width: 100},
        {field: 'name', headerI18n: 'subject', mobileWithHeader: true},
        {field: 'projectLabel', headerI18n: 'project', mobileWithHeader: true},
        {field: 'ourReferenceName', headerI18n: 'our_reference', mobileWithHeader: true},
        // {field: 'startDate', headerI18n: 'start_date', width: 100, mobileWithHeader: true},
        // {field: 'endDate', headerI18n: 'end_date', width: 100, mobileWithHeader: true},
        // {
        //     field: 'price',
        //     headerI18n: 'price',
        //     body: GridTemplates.currency,
        //     align: 'right',
        //     width: 120,
        //     footer: FooterAggregate.SUM_CURRENCY,
        //     mobileWithHeader: true
        // },
        // {field: 'note', headerI18n: 'note', body: GridTemplates.stringLimit100, mobile: true},
    ].filter(item => !item.hidden)

    const leftFilters = [
        roles.hasRole(Role.SUPPLIERCONTACT) ? <div>
            <Label/>
            <AddButton/>
        </div> : undefined,
        <div>
            <Label valueI18n='status'/>
            <XlnzDropdown
                value={filter.status}
                options={[QnaStatus.ONGOING, QnaStatus.COMPLETED]}
                optionLabel='labelMultiple'
                onChange={e => filter.update('status', e.value)}
                style={{width: '120px'}}/>
        </div>
    ]

    if (hasTimefilter()) {
        leftFilters.push(<div>
            <Label valueI18n='year'/>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}
            />
        </div>)
        leftFilters.push(<div>
            <Label valueI18n='month'/>
            <MonthDropdown value={filter.month} onChange={e => {
                filter.update('month', e.value)
            }}/>
        </div>)
    }

    return <>
        <Grid id='QnaGrid'
              labelI18n='questions_and_answers'
              icon={Icon.QNA}
              leftFilters={leftFilters}
              filter={filter}
              updateFilter={filter.update}
              columns={columns}
              value={filteredList}
              paginator={true}
        />
        {dialog.render()}
    </>
}

export default SupplierQnaGrid;
