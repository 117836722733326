import {faBars, faDatabase, faGear, faPowerOff, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useContext, useRef} from 'react';
import AppContext from "../../AppContext";
import CompanyAccessType from "../../enums/CompanyAccessType";
import useDialog from "../../hooks/useDialog";
import useMediaQuery from "../../hooks/useMediaQuery";
import useSecurity from "../../hooks/useSecurity";
import {i18n} from "../../I18n/I18n";
import logo from "../../resources/images/logo.png";
import ArrayUtil from "../../utils/ArrayUtil";
import Env from "../../utils/Env";
import ChangePassword from "../ChangePassword/ChangePassword";
import CompanySwitch from "../CompanySwitch/CompanySwitch";
import './DesktopHeader.scss';
import XlnzMenu from "../XlnzMenu/XlnzMenu";
import ChangeLanguage from "./ChangeLanguage";
import HelpDialog from "./HelpDialog";
import RecommendVerktyg1 from "./RecommendVerktyg1";

const DesktopHeader = props => {
    const context = useContext(AppContext)

    const moduleItems = props.moduleItems
    const registerModuleItems = props.registerModuleItems
    const settingModuleItems = props.settingModuleItems

    const dialog = useDialog()

    const menuRef = useRef();
    const security = useSecurity()
    const mediaQuery = useMediaQuery()

    const onMenuSelect = (e, item) => {
        menuRef.current.hide(e)
        item.command()
    }

    const items = moduleItems.map(item => ({
        label: item.label,
        faicon: item.icon,
        command: e => {
            if (item.command) onMenuSelect(e, item)
            if (!Env.isTouchDevice()) {
                if (item.items) onMenuSelect(e, item.items[0])
            }
        },
        items: item.items && item.items.map(item => ({
            label: item.label,
            command: e => onMenuSelect(e, item)
        }))
    }))

    // const items = moduleItems.map(item => ContextMenuItem({
    //     label: item.label,
    //     icon: item.icon,
    //     command: e => {
    //         if (item.command) onMenuSelect(e, item)
    //         if (!Env.isTouchDevice()) {
    //             if (item.items) onMenuSelect(e, item.items[0])
    //         }
    //     },
    //     items: item.items && item.items.map(item => ({
    //         label: item.label,
    //         command: e => onMenuSelect(e, item)
    //     }))
    // }))

    if (registerModuleItems.length > 0) {
        items.push(
            {
                labelI18n: 'register',
                faicon: faDatabase,
                items: registerModuleItems.map(item => ({
                    label: item.label,
                    command: e => onMenuSelect(e, item)
                }))
            }
        )
    }

    // if (registerModuleItems.length > 0) {
    //     items.push(
    //         ContextMenuItem({
    //             i18n: 'register', icon: faDatabase,
    //             items: registerModuleItems.map(item => ({
    //                 label: item.label,
    //                 command: e => onMenuSelect(e, item)
    //             }))
    //         })
    //     )
    // }

    const settingItems = ArrayUtil.sortOnField([
            ...settingModuleItems.map(item => ({
                label: item.label,
                command: e => onMenuSelect(e, item)
            })),
            {
                label: i18n('change_password'),
                command: () => dialog.show(<ChangePassword/>),
                hidden: context.currentCompany.accessType !== CompanyAccessType.USER.id
            },
            {
                label: i18n('change_language'),
                command: () => dialog.show(<ChangeLanguage/>),
                hidden: context.currentCompany.accessType !== CompanyAccessType.USER.id
            }
        ].filter(item => !item.hidden),
        'label')
    if (settingItems.length > 0) {
        items.push({
            labelI18n: 'settings',
            faicon: faGear,
            items: settingItems
        })
    }

    items.push({
        labelI18n: 'log_out',
        faicon: faPowerOff,
        command: item => security.signOut('user-desktop-click')
    })

    // console.log("ITEMS", items)

    return <header className="DesktopHeader">

        {/*<TieredMenu model={items}*/}
        {/*            popup={true}*/}
        {/*            ref={menuRef}*/}
        {/*            appendTo={document.getElementById('App')}*/}
        {/*/>*/}
        {/*<XlnzMenu model={items} ref={menuRef}/>*/}
        <XlnzMenu model={items} ref={el => menuRef.current = el}
                  style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0
                  }}
        />


        <div className='menu-button' onClick={e => menuRef.current.toggle(e)}>
            <div>
                <FontAwesomeIcon icon={faBars} className='faIcon'/>
                {i18n('menu')}
            </div>
        </div>

        {!mediaQuery.isMobile() && <img id='logo' src={logo} alt=''/>}
        {/*{!mediaQuery.isMobile() && <img id='leaf' src={leaf} alt=''/>}*/}

        <div className='divider'/>

        {
            !mediaQuery.isMobile() && context.currentCompany.accessType === CompanyAccessType.USER.id &&
            <RecommendVerktyg1/>
        }
        <ChangeCompany onChangeRepo={() => this.setState({displayDialog: true})}/>

        {
            !mediaQuery.isMobile() &&
            <div className='help-button' onClick={e => {
                dialog.show(<HelpDialog/>)
            }}>
                <div>
                    <FontAwesomeIcon icon={faQuestionCircle} className='faIcon'/>
                    <span style={{fontSize: '16px'}}>{i18n('help')}</span>
                </div>
            </div>
        }
        {dialog.render()}
    </header>
}

const ChangeCompany = () => {
    const context = useContext(AppContext)
    const dialog = useDialog()

    const clickable = context.companies.length > 1

    let classNames = 'user-button'
    if (clickable) classNames += ' header-button-clickable'

    const onClickHandler = () => {
        if (clickable) dialog.show(<CompanySwitch/>)
    }

    return <div>
        <div className={classNames} onClick={onClickHandler}>
            <div>
                <span>{context.currentCompany.label}</span>
                <span>{context.user.name}</span>
            </div>
        </div>
        {dialog.render()}
    </div>

}

export default DesktopHeader;


