import NetUtils from "../../../utils/NetUtils";

const QnaService = {
    findAll: params => NetUtils.doGet('/api/qnas', params),
    findById: id => NetUtils.doGet('/api/qnas/' + id),
    findByProject: projectId => NetUtils.doGet('/api/qnas/project/' + projectId),
    update: model => NetUtils.doPost('/api/qnas', model),
    insertMessage: (id, message) => NetUtils.doPost('/api/qnas/' + id + '/message', {message: message}),

    downloadPdf: async id => NetUtils.downloadFile('/api/qnas/' + id + '/pdf'),
}
export default QnaService;