import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import SupplierQnaEditForm from "./edit/SupplierQnaEditForm";
import SupplierQnaGrid from "./SupplierQnaGrid";

const SupplierQnasIndex = {
    routeProps: {
        path: '/supplier/qnas',
        exact: true,
        element: <SupplierQnaGrid/>,
    },
    nameKey: 'questions_and_answers',
    icon: Icon.QNA,
    subModules: [{
        routeProps: {
            path: '/supplier/qnas/:id',
            exact: true,
            element: <SupplierQnaEditForm/>,
        }
    }],
    roles: [Role.SUPPLIERCONTACT]
};
export default SupplierQnasIndex;