import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {AddButton} from "../../components/Buttons/Button";
import ContextMenuItem from "../../components/ContextMenu/ContextMenuItem";
import MonthDropdown from "../../components/Date/MonthDropdown";
import YearFilter from "../../components/filters/YearFilter";
import {useFilter} from "../../components/Grid/filter";
import {FooterAggregate} from "../../components/Grid/getFooterGroup";
import Grid from "../../components/Grid/Grid";
import {infoAlert} from "../../components/Overlay/Alert";
import {confirmYes} from "../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Icon from "../../enums/Icon";
import {GridTemplates} from "../../GridTemplates";
import I18n from "../../I18n/I18n";
import {getLastDateOfYearMonth, todayMonth, todayYear} from "../../utils/DateUtils";
import AgreementService from "./AgreementService";

const AgreementInvoiceOverviewGrid = () => {
    const filter = useFilter('AgreementInvoiceOverviewGrid', [
        {name: 'year', defaultValue: todayYear()},
        {name: 'month', defaultValue: todayMonth()},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);

    const navigate = useNavigate()

    const loadData = useCallback(async () => {
        showLoading()
        const _list = await AgreementService.listInvoiceOverview(filter.year, filter.month)
        setList(_list);
        hideLoading()
    }, [filter.year, filter.month])

    useEffect(() => {
        loadData();
    }, [loadData])

    const columns = [
        {field: 'customerName', headerI18n: 'customer', minWidth: 200},
        {field: 'startDate', headerI18n: 'start_date', width: 100},
        {field: 'endDate', headerI18n: 'end_date', width: 100},
        {
            field: 'toInvoice', headerI18n: 'to_invoice', width: 100, align: 'right',
            body: GridTemplates.currencyTwoDecimals,
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS
        },
        {
            field: 'invoiced', headerI18n: 'invoiced', width: 100, align: 'right',
            body: GridTemplates.currencyTwoDecimals,
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS
        },
        {field: 'note', headerI18n: 'note', body: GridTemplates.stringLimit100, minWidth: 100},
    ];

    const leftFilters = [
        <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div>,
        <div>
            <label className="label">{I18n.t('year')}</label>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}/>
        </div>,
        <div>
            <label className="label">{I18n.t('month')}</label>
            <MonthDropdown value={filter.month}
                           onChange={e => {
                               filter.update('month', e.value)
                           }}
                           showClear={false
                           }/>
        </div>
    ]

    const contextMenu = [
        ContextMenuItem({
            i18n: 'do_invoice', icon: Icon.INVOICE,
            command: item => {
                const invoiceDate = getLastDateOfYearMonth(filter.year, filter.month)

                navigate('/invoices/0' +
                    '?customer=' + item.customer + '' +
                    '&customercontact=' + item.customercontact +
                    '&invoiceDate=' + invoiceDate
                )
            },
        }),
        ContextMenuItem({
            label: 'Fakturera alla', icon: Icon.INVOICE,
            command: item => {
                confirmYes(async () => {
                    showLoading()
                    const _resp = await AgreementService.invoiceAll(filter.year, filter.month)
                    hideLoading()
                    if (_resp.value?.length > 0) {
                        await loadData()
                        infoAlert(_resp.value)
                    }
                })
            },
        }),
    ]

    return <Grid
        forceDesktop={true}
        labelI18n='invoice_overview'
        icon={Icon.AGREEMENT}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={list}
        contextMenu={contextMenu}
    />
}

export default AgreementInvoiceOverviewGrid;
