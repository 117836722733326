export function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}


export const projectNumberComparator = (a, b) => {
    if (!a || !b) {
        console.error("projectNumberComparator: A:B", a, ":", b)
        return 0;
    }
    const regex = /(\d+|\D+)/g;
    const numRegex = /^\d+$/;
    const partsA = a.match(regex);
    const partsB = b.match(regex);
    const minLength = Math.min(partsA.length, partsB.length);

    for (let i = 0; i < minLength; i++) {
        if (partsA[i] === partsB[i]) continue;

        const isNumA = numRegex.test(partsA[i]);
        const isNumB = numRegex.test(partsB[i]);

        if (isNumA && isNumB) {
            const diff = parseInt(partsA[i], 10) - parseInt(partsB[i], 10);
            if (diff !== 0) {
                return diff;
            }
        } else {
            return partsA[i].localeCompare(partsB[i]);
        }
    }

    return partsA.length - partsB.length;
};