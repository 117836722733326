import {faHouse} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useRef} from 'react';
import {Controller} from "react-hook-form";
import Unit from "../../enums/Unit";
import Vat from "../../enums/Vat";
import calculatePrice from "../../functions/calculatePrice";
import {GridTemplates} from "../../GridTemplates";
import useUserPersmissions from "../../hooks/useUserPersmissions";
import {i18n} from "../../I18n/I18n";
import InvoiceEntity from "../../modules/Invoices/edit/InvoiceEntity";
import {filterActive, findByField, findById, insert, isEmpty} from "../../utils/ArrayUtil";
import {isNumeric, NumberUtils, round} from "../../utils/NumberUtils";
import CheckboxEditor from "../Grid/CheckboxEditor";
import {addFreeRows, isFreeRow, numOfFreeRows} from "../Grid/freeRows";
import Grid from "../Grid/Grid";
import NumberGridInput from "../Grid/NumberGridInput";
import onRowChange from "../Grid/onRowChange";
import RemoveColumn from "../Grid/RemoveColumn";
import SelectEditor from "../Grid/SelectEditor";
import TextGridInput from "../Grid/TextGridInput";
import Tooltip from "../Overlay/Tooltip";
import './FormItemRowGrid.scss'
import getFormItemRowGridContextMenu from "./FormItemRowGridContextMenu";
import FormItemRowGridFooter from "./FormItemRowGridFooter";

const FormItemRowGrid = props => {
    const {
        control, name, label,
        disabled,
        articleList,
        pricelist,
        accountList,
        costcenterList,
        projectList,
        isCellEditable,
        defaultRowValue,
        vatType,
        housework = false,
        houseworkList,
        taxReductionType,
        footer,
        leftFooter,
        isColumnHidden,
        getCustomColumns,
        pagination,
        useArticleId = true
    } = props

    const numOfFreeRows = props.numOfFreeRows >= 0 ? props.numOfFreeRows : 3

    return <div className="formItem">
        <label htmlFor={name}>{label}</label>
        <Controller name={name}
                    control={control}
                    render={({field: {ref, value, onChange}}) =>
                        <RowGrid
                            inputRef={ref} value={value} onChange={onChange}
                            disabled={disabled}
                            numOfFreeRows={numOfFreeRows}
                            articleList={articleList}
                            pricelist={pricelist}
                            accountList={accountList}
                            costcenterList={costcenterList}
                            projectList={projectList}

                            isCellEditable={isCellEditable}
                            defaultRowValue={defaultRowValue}
                            vatType={vatType}
                            housework={housework}
                            houseworkList={houseworkList}
                            taxReductionType={taxReductionType}
                            footer={footer}
                            leftFooter={leftFooter}
                            isColumnHidden={isColumnHidden}
                            getCustomColumns={getCustomColumns}
                            pagination={pagination}
                            useArticleId={useArticleId}
                        />}/>
    </div>
}
export default FormItemRowGrid;


const RowGrid = props => {
    const {
        onChange,

        disabled,
        articleList,
        pricelist,
        accountList,
        costcenterList,
        projectList,

        isCellEditable,
        defaultRowValue,
        footer,
        leftFooter,
        vatType,
        housework,
        houseworkList,
        taxReductionType,
        isColumnHidden,
        getCustomColumns,
        pagination,
        useArticleId
    } = props

    const userPersmissions = useUserPersmissions()

    const HIDE_ARTICLE_PRICES = userPersmissions.isHideArticlePrices()

    function getNewRow() {
        return defaultRowValue
            ? Object.assign({}, defaultRowValue)
            : {
                id: 0,
                name: '',
                quantity: 0,
                aprice: 0,
                discount: 0,
                price: 0,
                vat: Vat.VAT25.id,
                percentageMarkup: 0,
                toInvoice: 0
            }
    }

    const rowList = addFreeRows(props.value, props.numOfFreeRows, getNewRow())

    if (projectList) rowList.forEach(item => item.projectLabel = item.project ? findById(projectList, item.project).label : '')

    const invoiceEntity = new InvoiceEntity(vatType, rowList, houseworkList, taxReductionType)

    let dataTable = useRef()

    const getPrice = r => {
        return calculatePrice(r.quantity, r.aprice, r.discount)
    }

    const fireChange = (list) => {
        const slicedList = list.slice(0, list.length - numOfFreeRows(list))
        onChange(slicedList);
    }

    const onQuatityOrApriceOrDiscountChange = (list, editorEvent, value) => {
        // console.log("editorEvent", list, editorEvent)

        const r = list[editorEvent.rowIndex]
        r.price = getPrice(r)
        editorEvent.rowData.price = r.price
        fireChange(list)
    }

    const onPriceChange = (list, editorEvent, value) => {
        // Do not update when the same value is passed.
        if (editorEvent.newValue === editorEvent.oldValue) return

        const r = list[editorEvent.rowIndex]
        r.price = NumberUtils.round(value, 2)
        r.quantity = 1
        r.aprice = NumberUtils.round(r.price / (1 - r.discount / 100), 2)

        editorEvent.rowData.name = r.name
        editorEvent.rowData.quantity = r.quantity
        editorEvent.rowData.aprice = r.aprice
        editorEvent.rowData.price = r.price

        updatePercentageMarkup(r, editorEvent)
        fireChange(list)
    }

    // const [selectedRow, setSelectedRow] = useState();

    const onArticleChange = e => {
        const {rowData, newValue, originalEvent: event} = e;
        if (newValue) {
            const article = useArticleId ? findById(articleList, newValue) : newValue
            rowData.article = useArticleId ? article.id : newValue
            rowData.name = article?.name || ''
            rowData.unit = article.unit

            if (pricelist) {
                const articleprice = findByField(pricelist.articlepriceList, 'article', article.id)
                rowData.aprice = articleprice?.price || 0

                //Fallback
                if (rowData.aprice === 0) {
                    rowData.aprice = article?.price || 0
                }
            } else {
                rowData.aprice = article?.price || 0
            }

            if (accountList && article.accountId) {
                const account = findById(accountList, article.accountId)
                rowData.account = account?.id
            }
        } else {
            rowData.article = undefined
        }
        event?.preventDefault()
        fireChange([...rowList])
    }

    const onPercentageMarkupChange = (list, editorEvent, value) => {
        const r = list[editorEvent.rowIndex]
        r.percentageMarkup = NumberUtils.round(value, 2)
        r.toInvoice = NumberUtils.round(r.price * (1 + r.percentageMarkup / 100), 2)
        editorEvent.rowData.percentageMarkup = r.percentageMarkup
        editorEvent.rowData.toInvoice = r.toInvoice
        fireChange(list)
    }

    const updatePercentageMarkup = (r, editorEvent) => {
        r.percentageMarkup = round((r.toInvoice / r.price - 1) * 100, 2)
        if (!isNumeric(r.percentageMarkup)) r.percentageMarkup = 0
        if (editorEvent) editorEvent.rowData.percentageMarkup = r.percentageMarkup
    }
    rowList.forEach(item => updatePercentageMarkup(item))

    const onToInvoiceChange = (list, editorEvent, value) => {
        const r = list[editorEvent.rowIndex]
        r.toInvoice = NumberUtils.round(value, 2)
        updatePercentageMarkup(r, editorEvent)
        editorEvent.rowData.toInvoice = r.toInvoice
        fireChange(list)
    }

    const onProjectChange = (list, editorEvent, projectId) => {
        const rowData = list[editorEvent.rowIndex]
        const project = findById(projectList, projectId)
        rowData.project = project?.id > 0 ? project.id : undefined
        if (project?.purchasePercentageMarkup > 0) {
            onPercentageMarkupChange(list, editorEvent, project?.purchasePercentageMarkup)
        }
        rowData.costcenter = project?.costcenter > 0 ? findById(costcenterList, project.costcenter)?.id : undefined
        fireChange([...rowList])
    }

    function _isCellEditable(e) {
        if (disabled) return false
        if (isCellEditable && !isCellEditable(e.field, e.rowData)) {
            dataTable?.current?.closeEditingCell()
            return false
        }
        return true
    }

    const getStringLimitBody = (label, width) => <Tooltip value={label}>
        <div className='nowrap' style={{width: width + 'px'}}>{label}</div>
    </Tooltip>;

    const columns = [
        {
            field: 'article',
            header: i18n('article'),
            // body: row => getStringLimitBody(row.article?.label, 150),
            body: row => {
                if (useArticleId) {
                    const article = findById(articleList, row?.article)
                    return getStringLimitBody(article?.label, 150)
                } else {
                    return getStringLimitBody(row.article?.label, 150)
                }
            },
            excelBody: row => row.article?.label,
            editor: e => _isCellEditable(e) ?
                <SelectEditor event={e} options={filterActive(articleList)} optionLabel='label'
                              optionValue={useArticleId ? 'id' : undefined}
                              onChange={e2 => e.editorCallback(e2?.value)}
                /> : null,
            onCellEditComplete: e => {
                onArticleChange(e)
            },
            width: 150,
            hide: articleList === undefined
        },
        {
            field: 'name', header: i18n('description'),
            // editor: e => _isCellEditable(e)
            //     ? <InputText
            //         value={e.value}
            //         maxLength={1000}
            //         onChange={e2 => e.editorCallback(e2.target.value)}
            //     /> : null,
            // onCellEditComplete: e => onRowChange(e, e.newValue, fireChange)
            editor: e => <TextGridInput editorEvent={e} maxLength={1000}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
        },
        {
            field: 'housework',
            header: <FontAwesomeIcon icon={faHouse}/>,
            excelHeaderI18n: 'house_work',
            body: (rowData, e) => {
                if (!rowData.housework) {
                    if (isFreeRow(e.props.value, rowData)) {
                        return <span/>
                    }
                }
                e.rowData = rowData
                return CheckboxEditor(e, fireChange, disabled)
            },
            rendererIsEditor: true,
            excelBody: (row, column) => i18n(row.housework ? 'yes' : 'no'),
            // style: {width: '26px'},
            width: 26,
            align: 'center',
            hide: !housework
        },
        {
            field: 'unit',
            header: i18n('unit'),
            body: GridTemplates.unit,
            editor: e => _isCellEditable(e) ? <SelectEditor
                event={e} options={Unit.values()} optionValue='id'
                onChange={e2 => e.editorCallback(e2?.value)}
            /> : null,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 95,
        },
        {
            field: 'quantity',
            header: i18n('quantity'),
            body: GridTemplates.numberTwoDecimals,
            excelBody: (row, column) => row[column.field],
            editor: e => _isCellEditable(e) ? <NumberGridInput editorEvent={e}/> : null,
            onCellEditComplete: e => onRowChange(e, e.newValue, onQuatityOrApriceOrDiscountChange),
            width: 80,
            align: 'right'
        },
        {
            field: 'aprice',
            header: i18n('aprice'),
            body: GridTemplates.numberTwoDecimals,
            excelBody: (row, column) => row[column.field],
            editor: e => _isCellEditable(e) ?
                <NumberGridInput editorEvent={e}/> : null,
            onCellEditComplete: e => onRowChange(e, e.newValue, onQuatityOrApriceOrDiscountChange),
            width: 90,
            align: 'right',
            hide: HIDE_ARTICLE_PRICES
        },
        {
            field: 'discount',
            header: i18n('discount'),
            body: GridTemplates.percentTimes100,
            excelBody: (row, column) => row[column.field] / 100,
            editor: e => _isCellEditable(e) ? <NumberGridInput editorEvent={e}/> : null,
            onCellEditComplete: e => onRowChange(e, e.newValue, onQuatityOrApriceOrDiscountChange),
            width: 50,
            align: 'right',
            hide: HIDE_ARTICLE_PRICES
        },
        {
            field: 'price',
            header: i18n('sum'),
            body: GridTemplates.numberTwoDecimals,
            excelBody: (row, column) => row[column.field],
            editor: e => _isCellEditable(e) ? <NumberGridInput editorEvent={e}/> : null,
            onCellEditComplete: e => onRowChange(e, e.newValue, onPriceChange),
            width: 100,
            align: 'right',
            hide: HIDE_ARTICLE_PRICES
        },
        {
            field: 'project',
            header: i18n('project'),
            body: row => getStringLimitBody(row.projectLabel, 120),
            excelBody: (row, column) => row?.projectLabel,
            editor: disabled ? undefined : e => <SelectEditor
                event={e}
                options={projectList} optionLabel='label' optionValue='id'
                onChange={e2 => e.editorCallback(e2?.value)}
            />,
            onCellEditComplete: e => onRowChange(e, e.newValue, onProjectChange),
            width: 120,
            hide: isEmpty(projectList)
        },
        {
            field: 'percentageMarkup',
            header: i18n('percentage_markup'),
            body: (rowData, column) => rowData.percentageMarkup !== -100 ? GridTemplates.percentTimes100(rowData, column) : '',
            excelBody: (row, column) => row[column.field] / 100,
            editor: e => _isCellEditable(e) ? <NumberGridInput editorEvent={e}/> : null,
            onCellEditComplete: e => onRowChange(e, e.newValue, onPercentageMarkupChange),
            width: 80,
            align: 'right',
            hide: isEmpty(projectList)
        },
        {
            field: 'toInvoice',
            header: i18n('to_invoice'),
            body: GridTemplates.numberTwoDecimals,
            editor: e => _isCellEditable(e) ? <NumberGridInput editorEvent={e}/> : null,
            onCellEditComplete: e => onRowChange(e, e.newValue, onToInvoiceChange),
            width: 100,
            align: 'right',
            hide: isEmpty(projectList) || HIDE_ARTICLE_PRICES,

        },
        {
            field: 'account',
            header: i18n('account'),
            body: row => getStringLimitBody(findById(accountList, row.account)?.label, 90),
            excelBody: (row, column) => findById(accountList, row.account)?.label,
            editor: disabled ? undefined : e => <SelectEditor
                event={e}
                options={filterActive(accountList)} optionLabel='label' optionValue='id'
                onChange={e2 => e.editorCallback(e2?.value)}
            />,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 100,
            hide: isEmpty(accountList)
        },
        {
            field: 'costcenter',
            header: i18n('costcenter'),
            body: (row, column) => !isFreeRow(column.props.value, row)
                ? getStringLimitBody(findById(costcenterList, row.costcenter)?.label, 90)
                : '',
            excelBody: (row, column) => findById(costcenterList, row.costcenter)?.label,
            editor: disabled ? undefined : e => <SelectEditor
                event={e}
                options={filterActive(costcenterList)} optionLabel='label' optionValue='id'
                onChange={e2 => e.editorCallback(e2?.value)}
            />,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 100,
            hide: isEmpty(costcenterList)
        }
    ].filter(c => !c.hide && (!isColumnHidden || !isColumnHidden(c)));

    if (getCustomColumns) {
        getCustomColumns(rowList, fireChange).forEach(c => {
            if (c.afterField) insert(columns, c, columns.indexOf(findByField(columns, 'field', c.afterField)) + 1)
            else insert(columns, c, 0)
        })
    }

    if (!disabled) {
        columns.push(RemoveColumn(row => fireChange(rowList.filter(r => r !== row)),
            props.numOfFreeRows,
            rowData => !isCellEditable || isCellEditable('remove', rowData)))
    }

    const rowClassName = data => {
        return {
            'headline': data.headline,
        }
    }
    const cellClassName = (value, e) => {
        // console.log("value, e", value, e)
        const field = e.column.props.field
        // const rowData = e.rowData
        const rowData = e.props.value[e.rowIndex]

        switch (field) {
            case 'unit':
            case 'quantity':
            case 'aprice':
            case 'discount':
                return {
                    'only-total': rowData.onlyTotal
                }
            default:
                return
        }
    }


    const contextMenu = getFormItemRowGridContextMenu({
        disabled: disabled,
        rowList: rowList.slice(0, rowList.length - numOfFreeRows(rowList)),
        // selectedRow: selectedRow,
        getNewRow: getNewRow,
        fireChange: fireChange,
        columns: columns,
        isCellEditable: isCellEditable
    })

    return <div className='FormItemRowGrid'>
        <Grid disabled={disabled}
              scrollable={false}
            // scrollable={false}
              columns={columns}
              value={rowList}
              pagination={pagination}
              onRowReorder={disabled ? undefined : e => fireChange([...e.value])}
              sortable={false}
              contextMenu={contextMenu}
              rowClassName={rowClassName}
              cellClassName={cellClassName}
              hideFooter={true}
        />
        {footer && footer}
        {!footer && <FormItemRowGridFooter invoiceEntity={invoiceEntity} leftFooter={leftFooter}/>}
    </div>
}