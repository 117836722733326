import React, {useEffect, useRef} from 'react';
import Tooltip from "../Overlay/Tooltip";
import {isAfter, isBefore} from "../../utils/DateUtils";

const XlnzSchedulerProjectBlock = props => {
    const {
        periodStartDate,
        periodEndDate,
        planning,
        onResize,
        width,
        color,
        top,
        onClick,

        contextMenuOptions,
        setContextMenuProps,
    } = props;

    const blockRef = useRef(null);
    const startResizeRef = useRef(null);
    const endResizeRef = useRef(null);

    const startDate = planning.startDate
    const endDate = planning.endDate

    useEffect(() => {
        const block = blockRef.current;
        if (!block) return;

        const handleDragStart = (e) => {
            e.dataTransfer.setData('text/plain', JSON.stringify(planning));
            setTimeout(() => block.style.display = 'none', 0);
        };

        const handleDragEnd = (e) => {
            block.style.display = 'block';
        };

        block.addEventListener('dragstart', handleDragStart);
        block.addEventListener('dragend', handleDragEnd);

        return () => {
            block.removeEventListener('dragstart', handleDragStart);
            block.removeEventListener('dragend', handleDragEnd);
        };
    }, [planning]);

    const handleResizeStart = (e, edge) => {
        // e.stopPropagation();
        const initialX = e.clientX;
        const cellWidth = document.querySelector('.scheduler__cell').offsetWidth;

        const handleMouseMove = (moveEvent) => {
            // moveEvent.preventDefault()
            // moveEvent.stopPropagation()
            const deltaX = moveEvent.clientX - initialX;
            const daysChange = Math.round(deltaX / cellWidth);

            if (edge === 'start') {
                const newStartDate = new Date(startDate);
                newStartDate.setDate(newStartDate.getDate() + daysChange);
                if (newStartDate <= new Date(endDate)) {
                    onResize(planning.id, newStartDate.toISOString().split('T')[0], endDate, true);
                }
            } else {
                const newEndDate = new Date(endDate);
                newEndDate.setDate(newEndDate.getDate() + daysChange);
                if (newEndDate >= new Date(startDate)) {
                    onResize(planning.id, startDate, newEndDate.toISOString().split('T')[0], true);
                }
            }
        };

        const handleMouseUp = e => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            onResize(planning.id, planning.startDate, planning.endDate, false);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };


    const startedBeforeThisPeriod = isBefore(planning.startDate, periodStartDate)
    const endingAfterThisPeriod = isAfter(planning.endDate, periodEndDate)

    const startRadius = startedBeforeThisPeriod ? '0' : '8px';
    const endRadius = endingAfterThisPeriod ? '0' : '8px';

    return <Tooltip value={planning.note}>
        <div
            ref={blockRef}
            className="project-block"
            style={{
                width,
                backgroundColor: color,
                top,
                zIndex: parseInt(top) + 1,
                borderRadius: `${startRadius} ${endRadius} ${endRadius} ${startRadius}`,
            }}
            draggable="true"
        >
            {
                !startedBeforeThisPeriod &&
                <div
                    ref={startResizeRef}
                    className="project-block__resize project-block__resize--start"
                    onMouseDown={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleResizeStart(e, 'start')
                    }}
                />
            }

            <div className="project-block__content"
                 onClick={() => onClick(planning)}
                 onContextMenu={e => {
                     if (contextMenuOptions && !e.ctrlKey) {
                         e.preventDefault();
                         setContextMenuProps({
                             top: e.clientY,
                             left: e.clientX,
                             row: planning
                         })
                     }
                 }}
            >
                {planning.name}
            </div>

            {
                !endingAfterThisPeriod &&
                <div
                    ref={endResizeRef}
                    className="project-block__resize project-block__resize--end"
                    onMouseDown={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleResizeStart(e, 'end')
                    }}
                />
            }
        </div>
    </Tooltip>
}

export default XlnzSchedulerProjectBlock;

