import React, {useContext, useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import AppContext from "../../../../AppContext";
import {CancelButton, SaveButton} from "../../../../components/Buttons/Button";
import FormItemCheckbox from "../../../../components/EditForm/FormItemCheckbox";
import FormItemDate from "../../../../components/EditForm/FormItemDate";
import FormItemDropdown from "../../../../components/EditForm/FormItemDropdown";
import FormItemEmployee from "../../../../components/EditForm/FormItemEmployee";
import FormItemNumber from "../../../../components/EditForm/FormItemNumber";
import FormItemOptionalDate from "../../../../components/EditForm/FormItemOptionalDate";
import FormItemProject from "../../../../components/EditForm/FormItemProject";
import FormItemText from "../../../../components/EditForm/FormItemText";
import FormItemTextArea from "../../../../components/EditForm/FormItemTextArea";
import {hideLoading, showLoading} from "../../../../components/Overlay/Loading";
import Spinner from "../../../../components/Spinner/Spinner";
import TabPanel from "../../../../components/TabView/TabPanel";
import TabView from "../../../../components/TabView/TabView";
import BillingType from "../../../../enums/BillingType";
import Role from "../../../../enums/Role";
import useDialog from "../../../../hooks/useDialog";
import useEmployees from "../../../../hooks/useEmployees";
import useMountEffect from "../../../../hooks/useMountEffect";
import useRoles from "../../../../hooks/useRoles";
import {i18n} from "../../../../I18n/I18n";
import {findById} from "../../../../utils/ArrayUtil";
import {todayDate} from "../../../../utils/DateUtils";
import RouterUtils from "../../../../utils/RouterUtils";
import ProjectService from "../../ProjectService";
import ProjectStatus from "../../ProjectStatus";
import QnaService from "../QnaService";
import QnaType from "../QnaType";
import QnafollowerGrid from "./QnafollowerGrid";
import QnamessageGrid from "./QnamessageGrid";


const QnaEditForm = props => {
    const context = useContext(AppContext);
    const roles = useRoles()
    // const preload = usePreload()
    const dialog = useDialog()

    const employees = useEmployees()

    const disabled = !roles.hasRole(Role.PROJECT_WRITE)

    // const location = useLocation()
    const navigate = useNavigate()
    const {id} = useParams();

    const [projectList, setProjectList] = useState()

    // const customerList = preload.getCustomerList()

    const defaultValues = {
        id: 0,
        company: context.currentCompany.id,
        ourReference: employees.activeUsable.find(item => item.email === context.user.email)?.id,
        cuser: context.user.id,
        number: '',
        startDate: todayDate(),
        price: 0,
        note: '',
        messageList: [],
        followerList: []
    }

    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, setValue, control} = form

    const watchMessageList = useWatch({control, name: 'messageList'})
    const watchFollowerList = useWatch({control, name: 'followerList'})


    const watchProject = Number(useWatch({control, name: 'project'}))
    const project = findById(projectList, watchProject)

    const watchOurReference = useWatch({control, name: 'ourReference'})
    const ourReference = employees.findById(watchOurReference)


    const save = async data => {
        console.log("SAVE QNA", data)
        if (data.billingType === '') delete data.billingType

        return QnaService.update(data).then(_model => {
            if (_model) {
                navigate('/projects/qnas/' + _model.id, {replace: true})
                reset(_model)
                return _model
            }
        });
    }

    const onSubmit = () => {
        showLoading();
        const data = getValues();
        return save(data).then(async _model => {
            if (_model) {
                RouterUtils.goBack(navigate)
            }
            hideLoading();
        });
    }

    async function loadData() {
        showLoading();
        const _id = id > 0 ? id : getValues('id');

        let _model = _id > 0 ? await QnaService.findById(_id) : undefined;
        const date = _model?.startDate || todayDate()

        if (!projectList) {
            const [
                _projectList
            ] = await Promise.all([
                ProjectService.findAll({
                    status: ProjectStatus.ONGOING.id,
                    startDate: date,
                    endDate: date,
                    forceId: _model?.project
                })
            ]);
            setProjectList(_projectList);
        }

        reset(_model);
        hideLoading();
    }

    useMountEffect(async () => loadData())

    const onProjectChange = e => {
        const project = findById(projectList, Number(e.target.value))
        setValue('ourReference', project.projectLeader)
        // const customer = findById(customerList, project.customer)
        // setValue('customer', project.customer)
        // setValue('customercontact', project.customercontact)
    }


    if (!projectList) return <Spinner/>
    return (
        <div className="EditForm">
            <div className="headline">
                <h1>{i18n('question_and_answer')} {getValues('number')}</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="button-row">
                    <div className='left-buttons'>
                        <SaveButton onClick={handleSubmit(onSubmit)} disabled={isSubmitting}/>
                        <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                    </div>
                    <div className='divider'></div>
                    <div className='right-buttons'>
                        {/*<DownloadInvoiceButton handleSubmit={handleSubmit} save={save}/>*/}
                    </div>
                </div>

                <div className='columnContainer'>
                    <div className='column'>
                        <FormItemProject options={projectList} onChange={onProjectChange} required {...form}/>
                        <FormItemDropdown key='type' name='type' label={i18n('unit')}
                                          options={QnaType.values()}
                                          optionLabel='label'
                                          showSelect
                                          required
                                          width={160}
                                          {...form} />
                        <FormItemText name='name' labelI18n='name' maxLength={5000} required {...form}/>
                    </div>
                    <div className='column'>
                        <FormItemDate name='startDate' labelI18n='start_date' required {...form} />
                        <FormItemOptionalDate name='endDate' labelI18n='end_date' {...form} />
                        <FormItemDropdown key='billingType' name='billingType' labelI18n='billing_type'
                                          options={BillingType.values()}
                                          showSelect
                                          width={160}
                                          {...form} />
                        <FormItemNumber name='price' labelI18n='price' required {...form}/>
                    </div>
                    <div className='column'>
                        <FormItemEmployee name='ourReference' labelI18n='our_reference' required {...form}/>
                        <FormItemTextArea name='note' labelI18n='note' maxLength={5000} {...form}/>
                        <FormItemCheckbox name='cancelled' labelI18n='cancelled' {...form}/>
                    </div>
                </div>

                <TabView renderActiveOnly={false}>
                    <TabPanel key='messageList' header={i18n('message') + ' (' + (watchMessageList.length) + ')'}>
                        <Controller name='messageList'
                                    control={control}
                                    render={({field: {ref, value, onChange}}) =>
                                        <QnamessageGrid inputRef={ref} value={value} onChange={onChange}
                                                        getValues={getValues}
                                                        disabled={disabled}
                                                        dialog={dialog}
                                                        handleSubmit={handleSubmit}
                                                        save={save}
                                                        loadData={loadData}
                                        />
                                    }/>
                    </TabPanel>
                    <TabPanel key='followerList' header={i18n('followers') + ' (' + (watchFollowerList.length) + ')'}>
                        <Controller name='followerList'
                                    control={control}
                                    render={({field: {ref, value, onChange}}) =>
                                        <QnafollowerGrid inputRef={ref} value={value} onChange={onChange}
                                                         disabled={disabled}
                                                         project={project}
                                                         ourReference={ourReference}
                                        />
                                    }/>
                    </TabPanel>
                </TabView>
            </form>
            {dialog.render()}
        </div>
    );
}

export default QnaEditForm;
