import React, {useEffect, useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import DatePicker from "../../components/Date/DatePicker";
import Label from "../../components/EditForm/Label";
import YearFilter from "../../components/filters/YearFilter";
import {useFilter} from "../../components/Grid/filter";
import {FooterAggregate} from "../../components/Grid/getFooterGroup";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import XlnzDropdown from "../../components/XlnzDropdown/XlnzDropdown";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import {match} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useDialog from "../../hooks/useDialog";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {distinct, findById, sortByField, sum} from "../../utils/ArrayUtil";
import {todayDate, todayYear} from "../../utils/DateUtils";
import {formatPercent, round} from "../../utils/NumberUtils";
import {projectNumberComparator} from "../../utils/SortUtils";
import ProjectService from "./ProjectService";
import ProjectStatus from "./ProjectStatus";
import useProjectContextMenuItems from "./useProjectContextMenuItems";

const ProjectResultOverviewGrid = () => {
    const roles = useRoles()
    const preload = usePreload()
    const dialog = useDialog()

    const costcenterList = preload.get(PreloadType.COSTCENTER_LIST).filter(item => item.active)
    const employeeList = preload.get(PreloadType.EMPLOYEE_LIST)

    const filter = useFilter('ProjectGrid', [
        {name: 'status', defaultValue: ProjectStatus.ONGOING.id},
        {name: 'date', defaultValue: todayDate()},
        {name: 'year', defaultValue: todayYear()},
        {name: 'costcenter', defaultValue: undefined, values: costcenterList},
        {name: 'projectLeader', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const contextMenu = useProjectContextMenuItems({date: filter.date, dialog: dialog})

    const [list, setList] = useState([])
    const projectLeaderList = sortByField(distinct(list.map(item => item.projectLeader)).map(id => findById(employeeList, id)), 'name')
    const filteredList = list?.filter(item => match(filter, item, 'projectLeader'))
    // const filteredList = list?.filter(item => matchCostcenter(filter, item) && match(filter, item, 'projectLeader'))

    useEffect(() => {
        showLoading()
        const params = {
            status: filter.status,
            startDate: filter.date,
            endDate: filter.date,
            year: filter.year,
            costcenter: filter.costcenter,
            search: filter.search
        }
        ProjectService.listResultOverview(params).then(_list => {
            setList(_list);
            hideLoading()
        });
    }, [filter.status, filter.date, filter.year, filter.costcenter, filter.search])

    const uberColumns = [
        {
            header: i18n('costs').toUpperCase(),
            style: {backgroundColor: 'rgba(184, 207, 105, 0.4)'},
            startColumnField: 'employeeCost',
            colSpan: 3
        },
        {
            header: i18n('invoiced').toUpperCase(),
            style: {backgroundColor: 'rgba(184, 207, 105, 0.2)'},
            startColumnField: 'costplusInvoiced',
            colSpan: 5
        },
        {
            header: i18n('total').toUpperCase(),
            style: {backgroundColor: 'rgba(184, 207, 105, 0.6)'},
            startColumnField: 'invoiced',
            colSpan: 4
        },
    ]

    const COL_WIDTH = 100
    const columns = [
        {
            field: 'label',
            headerI18n: 'project',
            minWidth: 200,
            sortFunction: (p1, p2) => projectNumberComparator(p1.number, p2.number)
        },
        {field: 'customerName', minWidth: 150, headerI18n: 'customer', body: GridTemplates.onelineString, mobile: true},

        // INVOICED
        {
            field: 'costplusInvoiced',
            hasUberHeader: true,
            headerI18n: 'costplus_work',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right',
        },
        {
            field: 'tenderInvoiced',
            hasUberHeader: true,
            headerI18n: 'fixed_price',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right',
        },
        {
            field: 'rentInvoiced',
            hasUberHeader: true,
            headerI18n: 'rent',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right',
        },
        {
            field: 'supplierInvoiceInvoiced',
            hasUberHeader: true,
            headerI18n: 'supplier_invoice',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: 130,
            align: 'right',
        },
        {
            field: 'otherInvoiced',
            hasUberHeader: true,
            headerI18n: 'inv_other',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right',
        },


        // COSTS
        {
            field: 'employeeCost',
            hasUberHeader: true,
            headerI18n: 'employees',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right',
        },
        {
            field: 'articleCost',
            hasUberHeader: true,
            headerI18n: 'articles',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right',
        },
        {
            field: 'supplierinvoiceCost',
            hasUberHeader: true,
            headerI18n: 'supplier_invoices',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: 130,
            align: 'right',
        },
        {
            field: 'invoiced',
            hasUberHeader: true,
            headerI18n: 'invoiced',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right',
        },
        {
            field: 'cost',
            hasUberHeader: true,
            headerI18n: 'costs',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right',
        },
        {
            field: 'result',
            hasUberHeader: true,
            headerI18n: 'result',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: COL_WIDTH,
            align: 'right',
        },
        {
            field: 'resultPercentage',
            hasUberHeader: true,
            header: i18n('result') + ' %',
            body: GridTemplates.percentTimes100,
            footer: FooterAggregate.CUSTOM,
            footerFunction: (list) => {
                const result = sum(list, 'result')
                const invoiced = sum(list, 'invoiced')
                // return 'fep '+invoiced

                return invoiced !== 0
                    ? formatPercent(round(result / invoiced, 2))
                    : 0
            },
            width: 80,
            align: 'right',
        },
        // {field: 'note', headerI18n: 'note',}
    ]

    const leftFilters = [
        roles.hasRole(Role.TENDER_WRITE) ? <div>
            <Label value='&nbsp;'/>
            <AddButton/>
        </div> : undefined,
        <div>
            <Label valueI18n='status'/>
            <XlnzDropdown
                value={filter.status}
                options={ProjectStatus.values()}
                onChange={e => filter.update('status', e.value)}
                style={{width: '150px'}}/>
        </div>
    ]

    if (filter.status === ProjectStatus.COMPLETED.id || filter.status === ProjectStatus.CANCELLED.id) {
        leftFilters.push(<div>
            <Label valueI18n='year'/>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}/>
        </div>)
    }

    leftFilters.push(<div>
        <Label valueI18n='date'/>
        <DatePicker value={filter.date} onChange={date => filter.update('date', date)}/>
    </div>)

    if (costcenterList?.length > 0) {
        leftFilters.push(<div>
            <Label valueI18n='costcenter'/>
            <XlnzDropdown
                value={filter.costcenter}
                options={costcenterList}
                optionLabel='label'
                showClear={true}
                placeholder={i18n('select') + '...'}
                optionValue='id'
                onChange={e => filter.update('costcenter', e.value)}
                style={{width: '250px'}}/>
        </div>)
    }

    leftFilters.push(<div>
        <Label valueI18n='project_leader'/>
        <XlnzDropdown
            value={filter.projectLeader}
            options={projectLeaderList}
            optionLabel='name'
            showClear={true}
            placeholder={i18n('select') + '...'}
            optionValue='id'
            onChange={e => filter.update('projectLeader', e.value)}
            style={{width: '200px'}}/>
    </div>)

    // const contextMenu = [
    //     ContextMenuItem({
    //         i18n: 'project_summary', icon: Icon.LOG,
    //         command: item => dialog.show(<ProjectSummaryDialog projectId={item.id}/>)
    //     }),
    //     ContextMenuItem({
    //         i18n: 'edit_tender', icon: Icon.TENDER,
    //         command: item => navigate('/tenders/' + item.tenderId),
    //         hidden: row => !roles.hasRole(Role.TENDER_READ) || !row?.tenderId,
    //     }),
    //     ContextMenuItem({
    //         i18n: 'do_invoice', icon: Icon.INVOICE,
    //         command: item => navigate('/invoices/0?project=' + item.id),
    //         hidden: row => !roles.hasRole(Role.INVOICE_WRITE)
    //     }),
    // ]

    return <>
        <Grid
            id='ProjectResultOverviewGrid'
            forceDesktop={true}
            labelI18n='result_overview'
            icon={Icon.PROJECT}
            leftFilters={leftFilters}
            filter={filter}
            updateFilter={filter.update}
            uberColumns={uberColumns}
            columns={columns}
            value={filteredList}
            contextMenu={contextMenu}
            expandableField='parent'
        />
        {dialog.render()}
    </>
}

export default ProjectResultOverviewGrid;
