import React from "react";
import VatType from "../../enums/VatType";
import {i18n} from "../../I18n/I18n";
import {NumberUtils} from "../../utils/NumberUtils";

const FormItemRowGridFooter = props => {
    const {
        invoiceEntity,
        leftFooter
    } = props

    const vatPrices = invoiceEntity.vatPrices

    return <div className='footer'>
        <div className='left'>{leftFooter}</div>
        <div className='middle'></div>
        <div className='right'>
            <table>



                {
                    invoiceEntity.vatType === VatType.SEVAT.id &&
                    <tbody>
                    <tr>
                        <td>{i18n('total_excl_vat')}</td>
                        <td>{NumberUtils.formatCurrency(invoiceEntity.priceExclVat, 2)}</td>
                    </tr>
                    {
                        vatPrices.length > 1 &&
                        vatPrices.map(item => <tr key={item.vat} className='vat-detail'>
                            <td>{i18n('vat') + ' ' + item.vat + ' %'}</td>
                            <td>{NumberUtils.formatCurrency(item.price, 2)}</td>
                        </tr>)
                    }
                    {
                        invoiceEntity.vatPrice !== 0 &&
                        <tr>
                            <td>{i18n('vat')}</td>
                            <td>{NumberUtils.formatCurrency(invoiceEntity.vatPrice, 2)}</td>
                        </tr>
                    }
                    <tr>
                        <td colSpan={2}>
                            <hr/>
                        </td>
                    </tr>
                    <tr>
                        <td>{i18n('total_incl_vat')}</td>
                        <td>{NumberUtils.formatCurrency(invoiceEntity.priceInclVat, 2)}</td>
                    </tr>
                    {
                        invoiceEntity.houseworkClaimedPrice !== 0 &&
                        <tr>
                            <td>{i18n('tax_reduction')}</td>
                            <td>{NumberUtils.formatCurrency(-invoiceEntity.houseworkClaimedPrice, 2)}</td>
                        </tr>
                    }
                    {
                        invoiceEntity.pennyRoundingPrice !== 0 &&
                        <tr>
                            <td>{i18n('penny_rounding')}</td>
                            <td>{NumberUtils.formatCurrency(invoiceEntity.pennyRoundingPrice, 2)}</td>
                        </tr>
                    }
                    <tr className='to-pay'>
                        <td>{i18n('to_pay')}</td>
                        <td>{NumberUtils.formatCurrency(invoiceEntity.payPrice, 2)}</td>
                    </tr>
                    </tbody>
                }




                {
                    (invoiceEntity.vatType === VatType.EUREVERSEDVAT.id
                        || invoiceEntity.vatType === VatType.SEREVERSEDVAT.id
                    ) &&
                    <tbody>
                    <tr>
                        <td>{i18n('total')}</td>
                        <td>{NumberUtils.formatCurrency(invoiceEntity.priceExclVat, 2)}</td>
                    </tr>
                    {
                        invoiceEntity.pennyRoundingPrice !== 0 &&
                        <tr>
                            <td>{i18n('penny_rounding')}</td>
                            <td>{NumberUtils.formatCurrency(invoiceEntity.pennyRoundingPrice, 2)}</td>
                        </tr>
                    }
                    <tr className='to-pay'>
                        <td>{i18n('to_pay')}</td>
                        <td>{NumberUtils.formatCurrency(invoiceEntity.payPrice, 2)}</td>
                    </tr>
                    </tbody>
                }
            </table>
        </div>
    </div>
}
export default FormItemRowGridFooter