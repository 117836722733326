import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import Icon from "../../enums/Icon";
import {i18n} from "../../I18n/I18n";
import {findById, sortByField} from "../../utils/ArrayUtil"
import {generateDarkColorFromId} from "../../utils/ColorUtils";
import {
    addDays,
    getDayInMonth,
    getDayNameShort,
    getDaysBetween,
    getFirstDateOfWeek,
    getHolidayName,
    getMonthNameShort,
    isBefore,
    isHoliday,
    isWeekend
} from "../../utils/DateUtils";
import NetUtils from "../../utils/NetUtils";
import ContextMenu from "../ContextMenu/ContextMenu";
import ContextMenuItem from "../ContextMenu/ContextMenuItem";
import renderOverlay, {removeOverlay} from "../Overlay/renderOverlay";
import Tooltip from "../Overlay/Tooltip";
import useXlnzGridOutsideClickEvent from "../XlnzGrid/useXlnzGridOutsideClickEvent";
import XlnzSchedulerProjectBlock from './XlnzSchedulerProjectBlock'
import './XlnzScheduler.scss'

const BLOCK_HEIGHT = 20
const BLOCK_GAP = 3

const XlnzScheduler = props => {
    const {
        startDate,
        daylockSet = new Set(),
        employeeList,
        planningList,
        onPlanningAdd,
        onPlanningEdit,
        onPlanningChange,
    } = props

    const currentDate = getFirstDateOfWeek(startDate)
    // const [viewMode, setViewMode] = useState('week')
    const viewMode = 'week'

    const [contextMenuId, setContextMenuId] = useState()
    const [contextMenuProps, setContextMenuProps] = useState()

    const propsContextMenu = props.contextMenu || []

    if (propsContextMenu.length === 0) {
        propsContextMenu.push(
            ContextMenuItem({
                i18n: 'export_to_excel',
                icon: Icon.EXCEL,
                command: () => NetUtils.exportToExcel('name', undefined, [], {})
            })
        )
    }

    // const contextMenu = props.contextMenu?.map(item => {
    const contextMenu = propsContextMenu.length === 0 ? undefined : propsContextMenu.map(item => {
        const _item = {...item}
        _item.originalCommand = item.command
        _item.command = () => _item.originalCommand()
        _item.originalTemplate = item.template
        _item.template = (item, options) => _item.originalTemplate(item, options)
        return _item
    })

    useXlnzGridOutsideClickEvent(() => {
        setContextMenuProps(undefined)
    })

    function hideContextMenu() {
        if (contextMenuId) {
            removeOverlay(contextMenuId)
            setContextMenuId(undefined)
        }
    }

    useEffect(() => {
        hideContextMenu()
        if (contextMenuProps) {
            const id = renderOverlay(<ContextMenu options={contextMenu} {...contextMenuProps} />)
            setContextMenuId(id)
        }
        // eslint-disable-next-line
    }, [contextMenuProps]);


    function getDaysArray(start, end) {
        const arr = [];
        const dt = new Date(start);
        const endDate = new Date(end);

        while (dt <= endDate) {
            arr.push(new Date(dt).toISOString().split('T')[0]);
            dt.setDate(dt.getDate() + 1);
        }

        return arr;
    }

    const days = useMemo(() => {
        const startDate = new Date(currentDate)
        startDate.setDate(startDate.getDate() - startDate.getDay() + 1) // Start from Monday
        let endDate
        if (viewMode === 'week') {
            endDate = new Date(startDate)
            endDate.setDate(endDate.getDate() + 6)
        } else if (viewMode === '2weeks') {
            endDate = new Date(startDate)
            endDate.setDate(endDate.getDate() + 13)
        } else if (viewMode === 'month') {
            endDate = new Date(startDate)
            endDate.setMonth(endDate.getMonth() + 1)
            endDate.setDate(0) // Last day of the month
        }
        return getDaysArray(startDate, endDate)
    }, [currentDate, viewMode])

    const handleDragOver = useCallback((e) => {
        e.preventDefault()
        e.currentTarget.classList.add('scheduler__drag-over')
    }, [])

    const handleDragLeave = useCallback((e) => {
        e.preventDefault()
        e.currentTarget.classList.remove('scheduler__drag-over')
    }, [])

    const handleDrop = useCallback((e, employeeId, date) => {

        e.preventDefault()
        e.currentTarget.classList.remove('scheduler__drag-over')

        const droppedPlanning = JSON.parse(e.dataTransfer.getData('text/plain'))
        if (!droppedPlanning) return

        // Don't update if dropping on the same day and employee
        if (droppedPlanning.startDate === date && droppedPlanning.employee === employeeId) return

        const duration = getDaysBetween(droppedPlanning.startDate, droppedPlanning.endDate)
        const updatedPlanning = {
            ...droppedPlanning,
            employee: employeeId,
            startDate: date,
            endDate: addDays(date, duration - 1)
        }
        onPlanningChange(updatedPlanning)
    }, [onPlanningChange])

    const handleResize = useCallback((planningId, newStartDate, newEndDate, isResizing) => {
        const planning = findById(planningList, planningId)
        const updatedPlanning = {
            ...planning,
            startDate: newStartDate,
            endDate: newEndDate
        }
        onPlanningChange(updatedPlanning, isResizing)
    }, [planningList, onPlanningChange])

    const handleCellClick = (employeeId, date) => {
        onPlanningAdd(employeeId, date)
    }

    // function getNumActiveProjectDuringWeek(projects, employeeId) {
    //     let weekStartDate = days[0]
    //     let weekEndDate = days[days.length - 1]
    //
    //     const weekStart = new Date(weekStartDate)
    //     const weekEnd = new Date(weekEndDate)
    //
    //     return projects.filter(project => {
    //         // Kontrollera om det är rätt anställd
    //         if (project.employee !== employeeId) return false
    //
    //         // Konvertera projektets start- och slutdatum
    //         const projectStart = new Date(project.startDate)
    //         const projectEnd = new Date(project.endDate)
    //
    //         // Kontrollera om projektet överlappar med veckan
    //         return projectStart <= weekEnd && projectEnd >= weekStart
    //     }).length
    // }
    //
    // function getProjectsCountForDate(employeeId, date, planningList) {
    //     // Konvertera datumsträngen till ett Date-objekt för enkel jämförelse
    //     const targetDate = new Date(date);
    //
    //     // Filtrera planeringar för den givna anställda och kolla om datumet ligger inom planeringens tidsintervall
    //     const projectsForDay = planningList.filter(project =>
    //         project.employee === employeeId &&
    //         new Date(project.startDate) <= targetDate &&
    //         new Date(project.endDate) >= targetDate
    //     );
    //
    //     // Returnera antalet projekt som hittades
    //     return projectsForDay.length;
    // }

    function countProjectsForEmployeeOnDate(employeeId, date, planningList) {
        // Filtrera ut alla projekt för den givna anställda
        const filteredProjects = planningList.filter(
            project => project.employee === employeeId
        );

        // Räkna projekten som överlappar med det angivna datumet
        const projectsOnDate = filteredProjects.filter(project =>
            project.startDate <= date && project.endDate >= date
        );

        // Returnera antalet projekt
        return projectsOnDate.length;
    }

    function getMaxProjectsForEmployee(employeeId, planningList) {
        // Filtrera planeringar för den angivna anställda
        const filteredProjects = planningList.filter(
            project => project.employee === employeeId
        );

        // Samla alla relevanta datumintervall
        const dateIntervals = filteredProjects.map(project => ({
            startDate: new Date(project.startDate),
            endDate: new Date(project.endDate),
        }));

        // Hitta alla unika datum som används i projekten
        const allDates = new Set();
        dateIntervals.forEach(interval => {
            for (
                let d = new Date(interval.startDate);
                d <= interval.endDate;
                d.setDate(d.getDate() + 1)
            ) {
                allDates.add(d.toISOString().split('T')[0]); // Lägger till datum som YYYY-MM-DD
            }
        });

        // Räkna antalet projekt för varje datum
        let maxProjects = 0;

        allDates.forEach(date => {
            const targetDate = new Date(date);

            // Räkna projekten som överlappar det här datumet
            const overlappingProjects = dateIntervals.filter(interval =>
                interval.startDate <= targetDate && interval.endDate >= targetDate
            ).length;

            // Uppdatera max om det här datumet har fler projekt
            if (overlappingProjects > maxProjects) {
                maxProjects = overlappingProjects;
            }
        });

        return maxProjects;
    }


    // function getProjectRowIndex(date, planning) {
    //     const sortedPlanningList = sortByField(planningList, 'startDate')
    //
    //     const foo = sortedPlanningList.filter(item => isWithinInterval(date, item.startDate, item.endDate))
    //
    //     const filteredProjects = foo.filter(project => project.employee === planning.employee)
    //     const result = filteredProjects.findIndex(project => project.id === planning.id)
    //
    //     return result
    // }

    function getProjectRowIndex(date, planning, planningList) {
        // Sortera planeringslistan efter startdatum
        const sortedPlanningList = sortByField(planningList, 'startDate');

        // Filtrera bara planeringar för samma anställd
        const filteredProjects = sortedPlanningList.filter(
            project => project.employee === planning.employee
        );

        // Håll koll på vilka rader som är upptagna vid varje datum
        const rows = [];

        for (const project of filteredProjects) {
            // Hitta en ledig rad för projektet
            let placed = false;
            for (let i = 0; i < rows.length; i++) {
                // Kontrollera om det går att placera projektet på denna rad
                if (!rows[i].some(item =>
                    // Kolla om det överlappar tidsmässigt
                    !(project.endDate < item.startDate || project.startDate > item.endDate)
                )) {
                    rows[i].push(project); // Lägg till projektet på raden
                    if (project.id === planning.id) return i; // Returnera radindex för aktuellt projekt
                    placed = true;
                    break;
                }
            }

            // Om ingen rad var ledig, skapa en ny rad
            if (!placed) {
                rows.push([project]);
                if (project.id === planning.id) return rows.length - 1; // Returnera index för nya raden
            }
        }

        // Om något gick fel (borde inte hända)
        return -1;
    }


    const renderProjectBlocks = (employeeId, date) => {
        const projectsForDay = planningList.filter(project =>
            project.employee === employeeId &&
            project.startDate <= date &&
            project.endDate >= date
        )

        return projectsForDay.map((planning, index) => {
            const isFirstDay = planning.startDate === date || (date === currentDate && isBefore(planning.startDate, date))
            if (isFirstDay) {
                const projectDuration = Math.min(
                    getDaysBetween(planning.startDate, planning.endDate),
                    days.length - days.indexOf(date),
                    getDaysBetween(currentDate, planning.endDate)
                )

                return <XlnzSchedulerProjectBlock
                    key={planning.id}
                    onResize={handleResize}
                    periodStartDate={days[0]}
                    periodEndDate={days[days.length - 1]}
                    planning={planning}
                    width={`calc(${projectDuration * 100}% + ${projectDuration - 2}px)`}
                    color={generateDarkColorFromId(planning.project)}
                    top={`${BLOCK_GAP + getProjectRowIndex(date, planning, planningList) * (BLOCK_HEIGHT + BLOCK_GAP)}px`}
                    onClick={_model => onPlanningEdit(_model)}
                    contextMenuOptions={contextMenu}
                    setContextMenuProps={setContextMenuProps}
                />
            }
            return null
        })
    }

    function getHeaderName(_date) {
        return getDayNameShort(_date) + ' ' +
            getDayInMonth(_date) + ' ' +
            getMonthNameShort(_date).toLowerCase()
    }

    return (
        <div className="scheduler">
            {/*<div className="scheduler__controls">*/}
            {/*    <div className="scheduler__view-modes">*/}
            {/*        <button*/}
            {/*            className={`view-button ${viewMode === 'week' ? 'active' : ''}`}*/}
            {/*            onClick={() => setViewMode('week')}*/}
            {/*        >*/}
            {/*            Vecka*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            className={`view-button ${viewMode === '2weeks' ? 'active' : ''}`}*/}
            {/*            onClick={() => setViewMode('2weeks')}*/}
            {/*        >*/}
            {/*            2 veckor*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            className={`view-button ${viewMode === 'month' ? 'active' : ''}`}*/}
            {/*            onClick={() => setViewMode('month')}*/}
            {/*        >*/}
            {/*            Månad*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="scheduler__grid">
                <div className="scheduler__header">
                    <div className="scheduler__employee-column"></div>
                    {days.map(date => {
                        const holidayName = getHolidayName(date, true)
                        const locked = daylockSet && daylockSet.has(date)

                        return (
                            <div key={date} className="scheduler__day-column">
                                <Tooltip value={
                                    (holidayName || locked) &&
                                    <div>
                                        {holidayName}
                                        {locked ? <div>{i18n('day_locked')}</div> : ''}
                                    </div>
                                }>
                                    <span>{getHeaderName(date)}</span>
                                </Tooltip>
                            </div>
                        );
                    })}
                </div>
                <div className="scheduler__body">
                    {employeeList.map(employee => {
                        const maxProjectsForEmployee = getMaxProjectsForEmployee(employee.id, planningList)

                        return (
                            <div key={employee.id} className="scheduler__row">
                                <div className="scheduler__employee-name">{employee.name}</div>
                                {
                                    days.map(date => {
                                        const numOfActiveProjects = countProjectsForEmployeeOnDate(employee.id, date, planningList)
                                        const cellHeight = numOfActiveProjects * (BLOCK_HEIGHT + BLOCK_GAP)

                                        const totalCellHeight = maxProjectsForEmployee * (BLOCK_HEIGHT + BLOCK_GAP)
                                            + BLOCK_HEIGHT + BLOCK_GAP * 2 // The add button space.

                                        const addButtonHeight = totalCellHeight - cellHeight

                                        const redDay = isWeekend(date) || isHoliday(date, true)

                                        return <div
                                            key={`${employee.id}-${date}`}
                                            className={'scheduler__cell' + (redDay ? ' holiday' : '')}
                                            style={{height: totalCellHeight + 'px'}}
                                            onDragOver={handleDragOver}
                                            onDragLeave={handleDragLeave}
                                            onDrop={(e) => handleDrop(e, employee.id, date)}
                                        >
                                            {renderProjectBlocks(employee.id, date)}

                                            <div className='new-project-button-container'
                                                 style={{
                                                     bottom: '0px',
                                                     height: `${addButtonHeight}px`
                                                 }}
                                            >
                                                <div className='new-project-button'
                                                     onClick={() => handleCellClick(employee.id, date)}>
                                                    <FontAwesomeIcon icon={faPlus}/>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default XlnzScheduler

