import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {faCheck, faClose, faCopy, faDownload, faImage, faPlus, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import I18n from '@I18n';
import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import Icon from "../../enums/Icon";
import {getModule} from "../../modules/modules";
import './Button.scss';

const Button = props => {
    const {
        icon,
        faicon,
        label,
        labelI18n,
        className = {}
    } = props

    const _props = {...props}
    delete _props.labelI18n

    return (<div className={'Button ' + (className || '')}>
        <button type='button' {..._props}>
            {icon && <i className={icon}/>}
            {faicon && <FontAwesomeIcon icon={faicon} className='faIcon'/>}
            {(icon || faicon) && (label || labelI18n) && <span>&nbsp;</span>}
            {label && <div>{label}</div>}
            {labelI18n && <div>{I18n.t(labelI18n)}</div>}
        </button>

    </div>)
}
export default Button;

export const MainButton = props => <Button style={{
    backgroundColor: '#699b2c', color: '#fff'
}}
                                           {...props} />

export const AddButton = props => {
    const location = useLocation();
    const navigate = useNavigate();

    const params = props.params ? props.params : ''
    let module = getModule(location.pathname)
    let path
    if (module.onRowSelectPath) {
        path = module.onRowSelectPath
    } else {
        path = module?.routeProps?.path || module?.path
    }
    return <Button faicon={faPlus}
                   onClick={() => navigate(path + '/0' + params)}
                   style={{backgroundColor: '#699b2c', color: '#fff'}}
                   disabled={props.disabled}/>
}

export const CancelButton = props => <Button labelI18n='cancel' faicon={faXmark}
                                             className="p-button-secondary" {...props} />

export const CloseButton = props => <Button labelI18n='close' faicon={faClose}
                                            className="p-button-secondary" {...props} />

export const CopyButton = props => <Button faicon={faCopy} {...props} />

export const EmailButton = props => <Button label={I18n.t('send') + '...'} faicon={faEnvelope} {...props} />

export const ExportButton = props => <Button faicon={Icon.INTEGRATION} {...props} />

export const NewRowButton = props => <Button faicon={faPlus} {...props} />

export const LinkButton = props => <Button
    style={{
        backgroundColor: 'transparent', padding: '0', color: '#215ca0'
    }}
    {...props}
/>


export const PdfButton = props => <Button faicon={Icon.PDF} {...props} />
export const PdfMainButton = props => <MainButton faicon={Icon.PDF} {...props} />

export const SendButton = props => <MainButton faicon={faEnvelope} {...props} />

export const DownloadButton = props => <MainButton faicon={faDownload} {...props} />

export const GenerateButton = props => <Button faicon={Icon.GENERATE} {...props} />

export const FetchButton = props => <Button faicon={faPlus} {...props} />

export const RemoveButton = props => <Button label={I18n.t('remove')} icon="pi pi-trash"
                                             className="remove-button" {...props} />

export const SaveButton = props => <MainButton labelI18n='save' faicon={faCheck}
                                               className="p-button-success" {...props} />

export const ImagePreviewButton = props => <Button className='RightSideBarButton' faicon={faImage} {...props}/>
