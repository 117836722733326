import I18n from '@I18n';
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {AddButton} from "../../../components/Buttons/Button";
import ContextMenuItem from "../../../components/ContextMenu/ContextMenuItem";
import MonthDropdown from "../../../components/Date/MonthDropdown";
import Label from "../../../components/EditForm/Label";
import YearFilter from "../../../components/filters/YearFilter";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import PdfColumn from "../../../components/Grid/PdfColumn";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDropdown from "../../../components/XlnzDropdown/XlnzDropdown";
import BillingType from "../../../enums/BillingType";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import {match} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import useDialog from "../../../hooks/useDialog";
import useEmployees from "../../../hooks/useEmployees";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import {distinct, findById, sortByField} from "../../../utils/ArrayUtil";
import {formatTime, todayYear} from "../../../utils/DateUtils";
import ProjectSummaryDialog from "../ProjectSummary/ProjectSummaryDialog";
import QnaService from "./QnaService";
import QnaStatus from "./QnaStatus";
import QnaType from "./QnaType";

const QnaGrid = props => {
    const roles = useRoles()
    const dialog = useDialog()

    const filter = useFilter('QnaGrid', [
        {name: 'status', defaultValue: QnaStatus.ONGOING.id},
        {name: 'year', defaultValue: todayYear()},
        {name: 'month', defaultValue: undefined},
        {name: 'ourReference', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const employees = useEmployees()
    const employeeList = employees.activeUsable

    const [list, setList] = useState([]);
    const ourReferenceList = sortByField(distinct(list.map(item => item.ourReference))
        .filter(id => id > 0)
        .map(id => findById(employeeList, id)), 'name')
    const filteredList = list?.filter(item => match(filter, item, 'ourReference'))

    const navigate = useNavigate()

    const hasTimefilter = useCallback(() => [
                QnaStatus.COMPLETED.id,
                QnaStatus.CANCELLED.id].indexOf(filter.status) !== -1
            || !filter.status
        , [filter.status])


    const loadData = useCallback(async () => {
        showLoading()
        const params = {
            status: filter.status,
            year: hasTimefilter() ? filter.year : undefined,
            month: hasTimefilter() ? filter.month : undefined,
            search: filter.search
        }
        const _list = await QnaService.findAll(params)
        _list.forEach(item => {
            item.typeName = QnaType.findById(item.type).label
            item.billingTypeName = BillingType.findById(item.billingType)?.name || ''
        })
        setList(_list);
        hideLoading()

    }, [hasTimefilter, filter.status, filter.year, filter.month, filter.search])

    useEffect(() => {
        loadData()
    }, [loadData])


    const columns = [
        PdfColumn(() => true,
            rowData => rowData.number ? I18n.t('invoice') + ' ' + rowData.number : '',
            rowData => '/api/qnas/' + rowData.id + '/pdf'
        ),
        {
            field: 'number', headerI18n: 'number',
            tooltip: rowData => i18n('created_by') + ' ' + rowData.cuserName + ', ' + formatTime(rowData.ctime),
            width: 100, mobile: true, mobileBold: true
        },
        {field: 'typeName', headerI18n: 'type', mobileWithHeader: true, width: 100},
        {field: 'name', headerI18n: 'name', mobileWithHeader: true},
        {field: 'projectLabel', headerI18n: 'project', mobileWithHeader: true},
        {field: 'ourReferenceName', headerI18n: 'our_reference', mobileWithHeader: true},
        {field: 'startDate', headerI18n: 'start_date', width: 100, mobileWithHeader: true},
        {field: 'endDate', headerI18n: 'end_date', width: 100, mobileWithHeader: true},
        {field: 'billingTypeName', headerI18n: 'billing_type', width: 100},
        {
            field: 'price',
            headerI18n: 'price',
            body: GridTemplates.currency,
            align: 'right',
            width: 120,
            footer: FooterAggregate.SUM_CURRENCY,
            mobileWithHeader: true
        },
        {field: 'note', headerI18n: 'note', body: GridTemplates.stringLimit100, mobile: true},
    ].filter(item => !item.hidden)

    const leftFilters = [
        roles.hasRole(Role.PROJECT_WRITE) ? <div>
            <Label/>
            <AddButton/>
        </div> : undefined,
        <div>
            <Label valueI18n='status'/>
            <XlnzDropdown
                value={filter.status}
                options={QnaStatus.values()}
                optionLabel='labelMultiple'
                onChange={e => filter.update('status', e.value)}
                style={{width: '120px'}}/>
        </div>
    ]

    if (hasTimefilter()) {
        leftFilters.push(<div>
            <Label valueI18n='year'/>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}
            />
        </div>)
        leftFilters.push(<div>
            <Label valueI18n='month'/>
            <MonthDropdown value={filter.month} onChange={e => {
                filter.update('month', e.value)
            }}/>
        </div>)
    }

    leftFilters.push(<div>
        <Label valueI18n='our_reference'/>
        <XlnzDropdown
            value={filter.ourReference}
            options={ourReferenceList}
            optionLabel='name'
            showClear={true}
            placeholder={i18n('select') + '...'}
            optionValue='id'
            onChange={e => filter.update('ourReference', e.value)}
            style={{width: '200px'}}/>
    </div>)

    const contextMenu = [
        ContextMenuItem({
            i18n: 'project_summary', icon: Icon.LOG,
            command: item => dialog.show(<ProjectSummaryDialog projectId={item.project}/>),
            hidden: item => {
                // console.log("ITTEM", item)
                return !item?.project
            }
        }),
        ContextMenuItem({
            i18n: 'edit_project', icon: Icon.PROJECT,
            command: item => navigate('/projects/' + item.project),
            hidden: item => !item?.project
        }),
        ContextMenuItem({
            i18n: 'edit_tender', icon: Icon.TENDER,
            command: item => navigate('/tenders/' + item.tender),
            hidden: row => !roles.hasRole(Role.TENDER_READ) || !row?.tender,
        }),
    ]

    return <>
        <Grid id='QnaGrid'
              labelI18n='questions_and_answers'
              icon={Icon.QNA}
              leftFilters={leftFilters}
              filter={filter}
              updateFilter={filter.update}
              columns={columns}
              value={filteredList}
              paginator={true}
              contextMenu={contextMenu}
        />
        {dialog.render()}
    </>
}

export default QnaGrid;
