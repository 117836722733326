import React from "react";
import {useForm} from "react-hook-form";
import {PdfViewerModal} from "../../components/Appendix/PdfViewer";
import Button, {CancelButton, PdfButton} from "../../components/Buttons/Button";
import SendByEmailDialog from "../../components/Dialog/SendByEmailDialog";
import FormItemCheckbox from "../../components/EditForm/FormItemCheckbox";
import FormItemDropdown from "../../components/EditForm/FormItemDropdown";
import FormItemOptionalDate from "../../components/EditForm/FormItemOptionalDate";
import {useFilter} from "../../components/Grid/filter";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import renderOverlay from "../../components/Overlay/renderOverlay";
import XlnzDialog from "../../components/XlnzDialog/XlnzDialog";
import Icon from "../../enums/Icon";
import useDialog from "../../hooks/useDialog";
import {i18n} from "../../I18n/I18n";
import {formatUrlParams} from "../../utils/NetUtils";
import ProjectService from "./ProjectService";

export default function ConstructionLogDialog(props) {
    const {
        employeeList,
        toEmail,
        save,
        onHide
    } = props

    const filter = useFilter('ConstructionLogDialog', [
        {name: 'startDate', defaultValue: undefined},
        {name: 'endDate', defaultValue: undefined},
        {name: 'unnotarized', defaultValue: undefined},
        {name: 'subprojects', defaultValue: undefined},
        {name: 'hideEmployeeNames', defaultValue: false},
        {name: 'employee', defaultValue: undefined}
    ])

    const dialog = useDialog()

    const defaultValues = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        unnotarized: filter.unnotarized,
        subprojects: filter.subprojects,
        hideEmployeeNames: filter.hideEmployeeNames,
        employee: filter.employee,
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {handleSubmit} = form

    // const onSubmit = () => {
    //     const data = getValues();
    //     updateFilter(data)
    //     // onSave(supplierinvoicerowList.filter(item => item.priceToInvoice !== 0), {
    //     //     showTitle: data.showTitle,
    //     //     separatePercentageMarkup: data.separatePercentageMarkup,
    //     // })
    //     // onHide()
    // }

    function updateFilter(data) {
        filter.update('startDate', data.startDate)
        filter.update('endDate', data.endDate)
        filter.update('unnotarized', data.unnotarized)
        filter.update('subprojects', data.subprojects)
        filter.update('hideEmployeeNames', data.hideEmployeeNames)
        filter.update('employee', data.employee)
    }

    const downloadPdf = data => {
        showLoading()
        save().then(_model => {
            if (_model) {
                updateFilter(data)
                const params = {
                    startDate: data.startDate,
                    endDate: data.endDate,
                    unnotarized: data.unnotarized,
                    subprojects: data.subprojects,
                    hideEmployeeNames: data.hideEmployeeNames,
                    employee: data.employee,
                }

                renderOverlay(<PdfViewerModal
                    label={i18n('construction_log') + ' ' + _model.number}
                    url={'/api/projects/' + _model.id + '/construction-log-pdf' + formatUrlParams(params)}
                    landscape={true}/>)
            }
            hideLoading();
        })
    }

    function onSend(data) {
        showLoading()
        save().then(_model => {
            if (_model) {
                updateFilter(data)

                dialog.show(<SendByEmailDialog
                    email={toEmail}
                    onSend={async toEmail => {
                        showLoading();
                        const saveModel = {
                            toEmail: toEmail,
                            startDate: data.startDate,
                            endDate: data.endDate,
                            unnotarized: data.unnotarized,
                            subprojects: data.subprojects,
                            hideEmployeeNames: data.hideEmployeeNames,
                            employee: data.employee,
                        }

                        await ProjectService.sendConstructionLogByEmail(_model.id, saveModel)
                        onHide()
                        hideLoading()
                    }}
                />)
            }
            hideLoading();
        })

    }

    return <>
        <XlnzDialog header={i18n('construction_log')} onHide={onHide}
                    buttons={() => [
                        <Button key='send' faicon={Icon.SEND} label={i18n('send') + '...'}
                                onClick={handleSubmit(onSend)}/>,
                        <PdfButton key='preview' labelI18n='preview'
                                   onClick={e => handleSubmit(downloadPdf)()}/>,
                        // <MainButton key='save' labelI18n='save' onClick={e => handleSubmit(onSubmit)()}
                        //             disabled={isSubmitting}/>,
                        <CancelButton key='cancel' onClick={e => props.onHide()}/>
                    ]}
        >
            <div style={{width: '350px', display: 'flex', gap: '10px', flexDirection: 'column', marginBottom: '10px'}}>
                <div style={{display: 'flex', flexDirection: 'row', gap: '20px'}}>
                    <FormItemOptionalDate key='startDate' name='startDate' label={i18n('start_date')} {...form}/>
                    <FormItemOptionalDate key='endDate' name='endDate' label={i18n('end_date')} {...form}/>
                </div>
                <FormItemCheckbox key='unnotarized' name='unnotarized'
                                  labelI18n='unnotarized_hours_and_articles' {...form}/>
                <FormItemCheckbox key='subprojects' name='subprojects'
                                  labelI18n='subprojects' {...form}/>
                <FormItemCheckbox key='hideEmployeeNames' name='hideEmployeeNames'
                                  labelI18n='hide_employee_names' {...form}/>
                <FormItemDropdown key='employee' name='employee' label={i18n('employee')}
                                  options={employeeList}
                                  showSelect
                                  {...form} />
            </div>
        </XlnzDialog>
        {dialog.render()}
    </>
}